import * as AccordionPrimitive from '@radix-ui/react-accordion'
import React from 'react'
import { cn } from '@/client/utils'
import type { AccordionContentProps } from './types'

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  AccordionContentProps
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      `
        overflow-hidden
        transition-all
        data-[state=closed]:animate-accordion-up
        data-[state=open]:animate-accordion-down
        pb-4 pt-0
      `,
      className
    )}
    {...props}
  >
    {children}
  </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { AccordionContent }
