import { BoltIcon as BoltIconOutline, RectangleStackIcon } from '@heroicons/react/24/outline'
import type { Action, App, Workspace } from '@prisma/client'
import {
  AppData,
  AppEval,
  ChangeCommitIcon,
  DatasetIcon,
  FinetuneIcon,
  FolderIcon,
  StudioIcon,
} from '@/client/assets/icons/icons'
import { api } from '@/utils'
import { useCurrentAction } from './actions'
import { useCurrentApp } from './apps'
import { useUser } from './user'

export interface MenuItem {
  label: string
  category?: string
  shortcut?: string[]
  value: string | object
  icon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string | undefined
      titleId?: string | undefined
    } & React.RefAttributes<SVGSVGElement>
  >
  description?: string
  items?: MenuItem[]
  component?: React.JSX.Element
}

export interface MenuItems {
  [key: string]: MenuItem
}

function appsMenuItems(workspace: Workspace | undefined, apps: App[] | undefined) {
  const menuItems: MenuItems = {}
  if (!workspace || !apps) {
    return menuItems
  }

  apps?.map((app) => {
    menuItems[`app-${app.slug}`] = {
      label: app.name,
      category: 'Apps',
      value: `/${workspace.slug}/apps/${app.slug}`,
      icon: RectangleStackIcon,
    }
  })
  return menuItems
}

export function appMenuItems(workspace: Workspace | undefined, app: App | undefined) {
  const menuItems: MenuItems = {}
  if (!workspace || !app) {
    return menuItems
  }

  menuItems[`app-${app.slug}-actions`] = {
    label: `Actions`,
    category: `Apps: ${app.name}`,
    value: `/${workspace.slug}/apps/${app.slug}`,
    icon: FolderIcon,
  }
  menuItems[`app-${app.slug}-studio`] = {
    label: `Studio`,
    category: `Apps: ${app.name}`,
    value: `/${workspace.slug}/apps/${app.slug}`,
    icon: StudioIcon,
  }
  menuItems[`app-${app.slug}-datasets`] = {
    label: `Datasets`,
    category: `Apps: ${app.name}`,
    value: `/${workspace.slug}/apps/${app.slug}/datasets`,
    icon: DatasetIcon,
  }
  menuItems[`app-${app.slug}-data`] = {
    label: `Logs`,
    category: `Apps: ${app.name}`,
    value: `/${workspace.slug}/apps/${app.slug}/data`,
    icon: AppData,
  }
  menuItems[`app-${app.slug}-evaluate`] = {
    label: `Evaluate`,
    category: `Apps: ${app.name}`,
    value: `/${workspace.slug}/apps/${app.slug}/evaluate`,
    icon: AppEval,
  }
  menuItems[`app-${app.slug}-optimization`] = {
    label: `Finetune`,
    category: `Apps: ${app.name}`,
    value: `/${workspace.slug}/apps/${app.slug}/optimization`,
    icon: FinetuneIcon,
  }

  return menuItems
}

function actionMenuItems(workspace: Workspace | undefined, action: Action, app: App | undefined) {
  const menuItems: MenuItems = {}

  if (!workspace || !app) {
    return menuItems
  }
  menuItems[`action-${action.id}-data`] = {
    label: `Logged Data`,
    category: `Actions: ${action.name}`,
    value: `/${workspace.slug}/apps/${app.slug}/data/?action=${action.guid}`,
    icon: AppData,
  }
  menuItems[`action-${action.id}-edit`] = {
    label: `Edit in Studio`,
    category: `Actions: ${action.name}`,
    value: `/${workspace.slug}/apps/${app.slug}/actions/${action.slug}/studio`,
    icon: StudioIcon,
  }
  menuItems[`action-${action.id}-run`] = {
    label: `View all versions`,
    category: `Actions: ${action.name}`,
    value: `/${workspace.slug}/apps/${app.slug}/actions/${action.slug}/versions`,
    icon: ChangeCommitIcon,
  }

  return menuItems
}

function actionsMenuItems(workspace: Workspace | undefined, actions: Action[], apps: App[]) {
  const menuItems: MenuItems = {}
  if (!workspace || !apps) {
    return menuItems
  }

  const record: Record<number, App> = {}
  apps?.forEach((app) => {
    record[app.id] = app
  })

  actions?.map((action: Action) => {
    menuItems[`action-${action.id}`] = {
      label: action.name,
      category: 'Actions',
      value: `/${workspace.slug}/apps/${record[action.appId]?.slug || ''}/actions/${action.slug}`,
      icon: BoltIconOutline,
    }
  })
  return menuItems
}

export function useFetchCommandKMenuItems(workspace?: Workspace) {
  const { user } = useUser()
  const { app } = useCurrentApp()
  const { action } = useCurrentAction()

  let menuItems: MenuItems = {}

  const { data: apps } = api.app.getAll.useQuery(
    { workspaceId: workspace?.id || 0 },
    {
      enabled: user !== undefined && workspace !== undefined,
    }
  )

  if (apps) {
    menuItems = { ...menuItems, ...appsMenuItems(workspace, apps as App[]) }
  }

  const { data: actions } = api.action.getAllForWorkspace.useQuery(
    { workspaceId: workspace?.id || 0 },
    {
      enabled: user !== undefined && workspace !== undefined,
    }
  )

  if (actions) {
    menuItems = { ...menuItems, ...actionsMenuItems(workspace, actions as Action[], apps as App[]) }
  }

  if (app) {
    menuItems = { ...menuItems, ...appMenuItems(workspace, app as App) }
  }

  if (action) {
    menuItems = { ...menuItems, ...actionMenuItems(workspace, action as Action, app as App) }
  }

  return menuItems
}
