import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import React from 'react'
import { cn } from '@/client/utils'
import { ScrollBar } from './Scrollbar'

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    viewportClassName?: string
    scrollBarClassName?: string
  }
>(({ className, viewportClassName, scrollBarClassName, children, ...props }, ref) => (
  <ScrollAreaPrimitive.Root
    ref={ref}
    className={cn('relative overflow-hidden', className)}
    {...props}
  >
    {/* there is a css bug in Radix-ui - temp fix here: https://github.com/radix-ui/primitives/issues/926#issuecomment-1447283516 */}
    <ScrollAreaPrimitive.Viewport
      className={cn('h-full w-full rounded-[inherit] [&>div]:!block', viewportClassName)}
    >
      {children}
    </ScrollAreaPrimitive.Viewport>

    <ScrollBar className={cn('', scrollBarClassName)} />

    <ScrollAreaPrimitive.Corner />
  </ScrollAreaPrimitive.Root>
))

ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName

export { ScrollArea }
