import { cva } from 'class-variance-authority'

export const badgeVariants = cva(
  `
    inline-flex
    items-center
    justify-center
    gap-2
    rounded-2xl
    px-2
    py-[.1875rem]
    caption1
    font-medium
    transition-colors
    focus:outline-none
    capitalize
  `,
  {
    variants: {
      variant: {
        default: 'border-transparent bg-grey-100 text-grey-600 dark:bg-grey-500 dark:text-white',
        primary:
          'border-transparent bg-primary-lightest-2 text-primary dark:bg-primary dark:text-white',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)
