import { api } from '@/utils'
import { useUser } from './user'

export function useFetchDataTableConfig(workspaceId: number, appId: number) {
  const { user } = useUser()

  const { data, isLoading, error } = api.workspaceMeta.getTableConfig.useQuery(
    {
      workspaceId,
      appId,
    },
    {
      enabled: user !== undefined && workspaceId !== undefined,
    }
  )
  return {
    config: data,
    isLoading,
    error,
  }
}

export function useFetchWorkspaceConfig(workspaceId?: number) {
  const {
    data: workspaceConfig,
    isLoading,
    error,
  } = api.workspaceMeta.getWorkspaceConfig.useQuery(
    {
      workspaceId: workspaceId!,
    },
    {
      enabled: !!workspaceId,
    }
  )

  return {
    workspaceConfig,
    isLoading,
    error,
  }
}
