export type ScoreData = {
  scores: {
    [key: string]: string
  }
}

export type DeltaOutput = {
  evalOnEvalTypeId: number
  latestScore: number
  recentScore?: number
  historicalScore?: number
  deltaRecent?: number
  deltaHistorical?: number
}

export function calculateEvalDelta(resultMetadata: ScoreData[], totalCount: number | undefined) {
  let scoresDelta: DeltaOutput[] = []

  const scores = resultMetadata.map((metadata) => metadata?.scores || {})
  const scoreKeys = Object.keys(scores[0] || {})
  scoresDelta = scoreKeys.map((key) => {
    const deltas = scores.map((score) => score[key])
    return {
      evalOnEvalTypeId: parseInt(key),
      latestScore: parseFloat(deltas[0] ?? '0'),
      recentScore: parseFloat(deltas[1] ?? '0'),
      historicalScore: parseFloat(deltas[2] ?? '0'),
      deltaRecent: parseFloat(deltas[0] ?? '0') - parseFloat(deltas[1] ?? '0'),
      deltaHistorical:
        totalCount && totalCount > 2
          ? parseFloat(deltas[0] ?? '0') - parseFloat(deltas[deltas.length - 1] ?? '0')
          : undefined,
    }
  })
  return scoresDelta
}
