import { cn } from '@/client/utils'
import type { BadgeProps } from './types'
import { badgeVariants } from './variants'

const Badge = ({ className, variant, ...props }: BadgeProps) => {
  return (
    <div className={cn(badgeVariants({ variant: variant || 'default' }), className)} {...props} />
  )
}

export { Badge }
