import type { Components } from 'react-markdown'
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { cn } from '@/client/utils'
import { CopyButton } from './components'
import kluCodeTheme from './style'

const CodeBlock: Components['code'] = ({ inline, className, children }) => {
  const match = /language-(\w+)/.exec(className || '')

  return !inline && match && match.length === 2 ? (
    <div className="group relative whitespace-pre-wrap text-xs">
      <div className="invisible absolute right-0 top-0 rounded-lg p-3 group-hover:visible">
        <CopyButton text={String(children).replace(/\n$/, '')} />
      </div>

      <SyntaxHighlighter
        style={kluCodeTheme}
        lineProps={{ style: { whiteSpace: 'pre-wrap' } }}
        wrapLines={true}
        language={match[1]?.toLowerCase() ?? 'markdown'}
        PreTag="div"
        className="rounded-lg !bg-grey-50"
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    </div>
  ) : (
    <code
      className={cn(className, 'whitespace-pre-wrap rounded-lg bg-grey-100 px-1.5 py-1 text-xs')}
    >
      {children}
    </code>
  )
}

export { CodeBlock }
