import { ZodIssueCode, type z } from 'zod'

export const parseJSONPreprocessor = (value: unknown, ctx: z.RefinementCtx): object | unknown => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value) as object
    } catch (e) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        message: (e as Error).message,
      })
    }
  }

  return value
}
