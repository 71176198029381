import { randomBytes, randomUUID, scryptSync, timingSafeEqual } from 'crypto'

export function generateKey(size = 32) {
  const buffer = randomBytes(size)
  return buffer.toString('base64')
}

export function generateSecretHash(key: string) {
  const salt = randomBytes(8).toString('hex')
  const buffer = scryptSync(key, salt, 64)
  return `${buffer.toString('hex')}.${salt}`
}

export function compareKeys(storedKey: string, suppliedKey: string) {
  const [hashedPassword, salt] = storedKey.split('.')

  if (!hashedPassword || !salt) return false

  const buffer = scryptSync(suppliedKey, salt, 64)
  return timingSafeEqual(Buffer.from(hashedPassword, 'hex'), buffer)
}

export function generateUUID() {
  return randomUUID()
}

/* 

// Usage:

const key = generateKey() // send to user
const secretHash = generateSecretHash(key) // save in db

*/
