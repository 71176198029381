import { api } from '@/utils'
import { useUser } from './user'

export function useFetchFeedbackForData(dataId: number) {
  const { user } = useUser()
  const { data, isLoading, error, refetch } = api.feedback.getByDataId.useQuery(
    { dataId: dataId },
    { enabled: user !== undefined }
  )
  return {
    feedback: data,
    isLoading,
    error,
    refetch,
  }
}

export function useFetchBehaviourFeedbackValues() {
  const { data, isLoading, error } = api.feedback.getBehaviourFeedbackValues.useQuery()
  return {
    behaviourFeedbackValues: data,
    isLoading,
    error,
  }
}
