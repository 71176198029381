import { ComboboxOrCreate } from '@/client/components/Combobox/Create'
import { ComboboxMultiple } from './Multiple'
import { ComboboxSingle } from './Single'
import { ComboboxSkeleton } from './Skeleton'

const Combobox = {
  Single: ComboboxSingle,
  Multiple: ComboboxMultiple,
  Skeleton: ComboboxSkeleton,
  Create: ComboboxOrCreate,
}

export { Combobox }
