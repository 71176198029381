import { CheckCircleIcon } from '@heroicons/react/24/solid'
import type { ToasterProps } from 'sonner'
import { toast as primitiveToast, Toaster as ToasterPrimitive } from 'sonner'

export function Toaster(props: ToasterProps) {
  return <ToasterPrimitive {...props} position="bottom-right" closeButton />
}

interface ToastProps {
  id?: string
  title: string
  description?: string
  action?: {
    label: string
    onClick: () => void
  }
}

export const toast = {
  success: (props: ToastProps) =>
    primitiveToast.success(props.title, {
      id: props.id,
      description: props.description,
      action: props.action,
      classNames: {
        toast: 'border-0 bg-white',
        title: 'font-semibold text-black',
        description: 'font-normal text-grey-500',
        actionButton:
          'bg-green-700 text-white transition hover:bg-green-800 ease-in-out duration-300',
        closeButton: 'bg-white text-black',
      },
      icon: <CheckCircleIcon className="text-green-700 w-5 h-5" />,
    }),
  info: (props: ToastProps) =>
    primitiveToast.info(props.title, {
      description: props.description,
      action: props.action,
      classNames: {
        title: 'font-semibold',
        description: 'font-normal text-grey-500',
        closeButton: 'bg-white text-black',
      },
    }),
  error: (props: ToastProps) =>
    primitiveToast.error(props.title, {
      description: props.description,
      action: props.action,
      classNames: {
        toast: 'border-0 bg-red-700 text-red-100',
        title: 'font-semibold',
        description: 'font-normal opacity-70',
        actionButton: 'bg-red-100 text-red-700',
        closeButton: 'bg-red-100 text-red-700 border-red-200',
      },
    }),
  warning: (props: ToastProps) =>
    primitiveToast.warning(props.title, {
      description: props.description,
      action: props.action,
      classNames: {
        toast: 'bg-yellow-100 text-orange-800 border-0',
        title: 'font-semibold',
        description: 'font-normal opacity-50',
        actionButton: 'bg-orange-800 text-yellow-100',
        closeButton: 'bg-yellow-100 text-orange-800 border-yellow-300',
      },
    }),
}
