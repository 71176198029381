import * as TabsPrimitive from '@radix-ui/react-tabs'
import React from 'react'
import { cn } from '@/client/utils'

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      `
        mt-2
        text-grey-800
        ring-offset-white
        focus-visible:outline-none
        focus-visible:ring-2
        focus-visible:ring-grey-400
        focus-visible:ring-offset-2
        dark:text-grey-50
        dark:ring-offset-grey-950
        dark:focus-visible:ring-grey-800
      `,
      className
    )}
    {...props}
  />
))

TabsContent.displayName = TabsPrimitive.Content.displayName

export { TabsContent }
