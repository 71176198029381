import React from 'react'
import { cn } from '@/client/utils'
import type { CommandShortcutProps } from './types'

const CommandShortcut = ({ className, ...props }: CommandShortcutProps) => {
  return (
    <span
      className={cn('ml-auto text-xs tracking-widest text-grey-500 dark:text-grey-400', className)}
      {...props}
    />
  )
}

CommandShortcut.displayName = 'CommandShortcut'

export { CommandShortcut }
