import * as SelectPrimitive from '@radix-ui/react-select'
import React from 'react'
import ChevronDownIcon from '@/client/assets/icons/chevron-down.svg'
import { Icon } from '@/client/components/Icon'
import { cn } from '@/client/utils'
import type { SelectTriggerProps } from './types'

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(({ className, hasError, children, ...props }, ref) => {
  const { disabled } = props

  return (
    <SelectPrimitive.Trigger
      ref={ref}
      className={cn(
        `
          flex
          h-9
          w-full
          items-center
          justify-between
          rounded-md
          border
          border-grey-300
          hover:border-grey-400
          bg-white
          hover:bg-grey-50
          px-3
          py-2
          text-sm
          font-regular
          focus:border-grey-400
          focus:outline-none
          focus:ring-0
          disabled:border-grey-100
          disabled:bg-grey-100
          shadow-sm
          disabled:text-sm
          data-[state=open]:border-grey-400
          data-[placeholder]:text-grey-500
          data-[placeholder]:hover:text-grey-600
          dark:border-grey-600
          dark:bg-black
          dark:ring-offset-grey-950
          dark:focus:border-white
          dark:disabled:border-grey-700
          dark:disabled:bg-grey-700
          dark:data-[state=open]:border-white
          dark:data-[placeholder]:text-grey-400
          [&[data-state=open]>.select-chevron]:rotate-180
        `,
        {
          'border-error focus-within:border-error': hasError,
        },
        className
      )}
      {...props}
    >
      {children}

      <SelectPrimitive.Icon asChild>
        <Icon
          className={cn(
            'select-chevron transition-transform duration-200 ml-2',
            disabled ? 'text-grey-300 dark:text-grey-600' : 'text-grey-400'
          )}
          size="sm"
          component={ChevronDownIcon}
        />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  )
})

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

export { SelectTrigger }
