import * as SliderPrimitive from '@radix-ui/react-slider'
import React from 'react'
import { cn } from '@/client/utils'
import type { SliderProps } from './types'

const Slider = React.forwardRef<React.ElementRef<typeof SliderPrimitive.Root>, SliderProps>(
  ({ className, ...props }, ref) => (
    <SliderPrimitive.Root
      ref={ref}
      className={cn('relative flex w-full touch-none select-none items-center', className)}
      {...props}
    >
      <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-grey-200 dark:bg-grey-700">
        <SliderPrimitive.Range className="absolute h-full bg-grey-800 dark:bg-grey-50" />
      </SliderPrimitive.Track>

      <SliderPrimitive.Thumb
        className={`
          cursor-grab
          block
          h-4
          w-4
          rounded-full
          border-2
          border-white
          bg-grey-800
          ring-offset-white
          transition-colors
          focus-visible:outline-none
          focus-visible:ring-2
          focus-visible:ring-grey-200
          focus-visible:ring-offset-2
          disabled:pointer-events-none
          disabled:opacity-50
          dark:border-grey-950
          dark:bg-grey-50
          dark:ring-offset-grey-950
          dark:focus-visible:ring-grey-700
        `}
      />
    </SliderPrimitive.Root>
  )
)

Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
