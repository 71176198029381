import { useSession } from 'next-auth/react'
import { api } from '@/utils'

export function useUser() {
  const { data: sessionData, status } = useSession()
  return {
    ...sessionData,
    status,
  }
}

export function fetchUserById(userId: string) {
  const { data, isLoading, error } = api.user.getById.useQuery({ userId })
  return {
    user: data,
    isLoading,
    error,
  }
}

export function fetchUsersByIds(userIds: string[]) {
  const { data, isLoading, error } = api.user.getManyByIds.useQuery({ userIds })
  return {
    users: data,
    isLoading,
    error,
  }
}
