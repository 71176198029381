import React from 'react'
import { Icon } from '@/client/components/Icon'
import { cn } from '@/client/utils'
import { Tooltip } from '../Tooltip'
import type { ButtonIconProps, ButtonProps } from './types'
import { buttonVariants } from './variants'

const ButtonIcon = ({ className, size, icon }: ButtonIconProps) => {
  return (
    <Icon
      className={cn(className)}
      size={size === 'xs' ? 'sm' : 'md'}
      component={icon}
      aria-hidden
    />
  )
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'primary',
      size = 'md',
      type = 'button',
      children,
      startIcon,
      endIcon,
      icon,
      loading,
      ...props
    },
    ref
  ) => {
    const renderButton = () => (
      <button
        className={cn(buttonVariants({ variant, size, className }), !children && 'p-2 gap-0')}
        ref={ref}
        {...props}
        type={type}
      >
        <>
          {loading ? (
            <div className="flex items-center space-x-2">
              <div
                className={cn('h-4 w-4 animate-spin rounded-full border-2 border-dashed mr-2', {
                  'border-white/80': variant === 'destructive',
                  'border-grey-500': variant !== 'destructive',
                })}
              ></div>
              {startIcon || endIcon ? <div className="px-1">{children}</div> : children}
            </div>
          ) : (
            <>
              {icon ? (
                <ButtonIcon size={size} icon={icon} />
              ) : (
                <>
                  {startIcon ? (
                    <ButtonIcon className="start-icon" size={size} icon={startIcon} />
                  ) : null}

                  {children ? (
                    startIcon || endIcon ? (
                      <div className="px-1">{children}</div>
                    ) : (
                      children
                    )
                  ) : (
                    <div className="px-0"></div>
                  )}

                  {endIcon ? <ButtonIcon className="end-icon" size={size} icon={endIcon} /> : null}
                </>
              )}
            </>
          )}
        </>
      </button>
    )

    if (props.tooltip) {
      const { content, sideOffset, delayDuration, ...rest } = props.tooltip
      return (
        <Tooltip.Root delayDuration={delayDuration ?? 333}>
          <Tooltip.Trigger asChild>{renderButton()}</Tooltip.Trigger>
          {content ? (
            <Tooltip.Content sideOffset={sideOffset ?? 10} {...rest}>
              {content}
            </Tooltip.Content>
          ) : null}
        </Tooltip.Root>
      )
    }

    return renderButton()
  }
)
Button.displayName = 'Button'

export { Button, ButtonIcon }
