import type { RuleGroupType } from 'react-querybuilder'
import { api } from '@/utils'
import type { DetailedCurator } from '../types/curator'
import { useUser } from './user'

export function useFetchCurators(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.curator.getAll.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    curators: data,
    isLoading,
    error,
  }
}

export function useFetchCuratorBySlug(slug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.curator.getBySlug.useQuery(
    { slug },
    {
      enabled: user !== undefined,
    }
  )
  return {
    curator: data as DetailedCurator,
    isLoading,
    error,
  }
}

export function useFetchFilteredDataCount({
  appId,
  workspaceId,
  filter,
  sampleRate,
  datasetId,
  since,
}: {
  appId: number
  workspaceId: number
  filter: RuleGroupType
  sampleRate?: number
  datasetId: number
  since?: string
}) {
  const { user } = useUser()

  const { data, isLoading, error } = api.curator.fetchFilteredDataCount.useQuery(
    { appId, filter, workspaceId, sampleRate, datasetId, since },
    {
      enabled: user !== undefined && filter !== undefined && workspaceId !== undefined,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  )

  return {
    count: data,
    isLoading,
    error,
  }
}
