import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'

interface RouteHistoryContextProps {
  prevPath: string
}

export const RouteHistoryContext = createContext<RouteHistoryContextProps>({ prevPath: '' })

export const RouteHistoryProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  const [prevPath, setPrevPath] = useState('')

  useEffect(() => {
    const handleRouteChange = () => {
      setPrevPath(router.asPath)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  return (
    <RouteHistoryContext.Provider value={{ prevPath }}>{children}</RouteHistoryContext.Provider>
  )
}

export const useRouteHistory = () => {
  return useContext(RouteHistoryContext)
}
