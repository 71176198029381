import { api } from '@/utils'
import { useUser } from './user'

export function useFetchTools(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.tool.getAll.useQuery(
    { workspaceId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    tools: data,
    isLoading,
    error,
  }
}

export function useFetchSkillLastUsed(skillId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.tool.getLastUsed.useQuery(
    {
      skillId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    skillLastUsed: data,
    isLoading,
    error,
  }
}

export function useFetchAppTools(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.tool.getAllForAppCount.useQuery(
    { appId },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    toolCount: data,
    isLoading,
    error,
  }
}

export function useFetchTool(toolId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.tool.get.useQuery(
    { id: toolId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    tool: data,
    isLoading,
    error,
  }
}

export function useFetchToolBySlug(toolSlug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.tool.getBySlug.useQuery(
    { slug: toolSlug },
    {
      enabled: user !== undefined,
    }
  )
  return {
    tool: data,
    isLoading,
    error,
  }
}

export function useFetchToolArgSchema({
  toolGuid,
  workspaceGuid,
}: {
  toolGuid: string
  workspaceGuid: string
}) {
  const { data, isLoading, error } = api.tool.getArgsSchema.useQuery({
    toolGuid,
    workspaceGuid,
  })

  return {
    argsSchema: data?.argsSchema,
    isLoading,
    error,
  }
}

export function useFetchToolTypes() {
  const { user } = useUser()
  const { data, isLoading, error } = api.toolType.getAll.useQuery(undefined, {
    enabled: user !== undefined,
  })
  return {
    toolTypes: data,
    isLoading,
    error,
  }
}

export function useFetchSkillInIds(skillIds: number[]) {
  const { user } = useUser()
  const { data, isLoading, error } = api.tool.getForIds.useQuery(
    { skillIds },
    {
      enabled: user !== undefined && skillIds.length > 0,
    }
  )
  return {
    skills: data,
    isLoading,
    error,
  }
}
