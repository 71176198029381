import { api } from '@/utils'
import { useUser } from './user'

export function useFetchDocumentsPaginated({
  indexId,
  page,
  perPage,
  query,
}: {
  indexId: number
  page: number
  perPage: number
  query?: string
}) {
  const { user } = useUser()

  const { data, isLoading, error } = api.document.getAllPaginated.useQuery(
    { indexId, page, perPage, query },
    {
      enabled: user !== undefined,
    }
  )

  return {
    documents: data && data.documents,
    totalDocuments: data && data.total,
    isLoading,
    error,
  }
}

export function useFetchDocument(documentId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.document.get.useQuery(
    {
      id: documentId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    document: data,
    isLoading,
    error,
  }
}

export function useFetchDocumenByGuid(documentGuid: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.document.getByGuid.useQuery(
    {
      guid: documentGuid,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    document: data,
    isLoading,
    error,
  }
}
