import { Command as CommandPrimitive } from 'cmdk'
import React from 'react'
import { cn } from '@/client/utils'
import type { CommandEmptyProps } from './types'

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  CommandEmptyProps
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className={cn('py-6 text-center text-sm', className)}
    {...props}
  />
))

CommandEmpty.displayName = CommandPrimitive.Empty.displayName

export { CommandEmpty }
