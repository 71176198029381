import { api } from '@/utils'
import { useUser } from './user'

export function useFetchActionVersion(versionId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.actionVersion.get.useQuery(
    { id: versionId },
    {
      enabled: user !== undefined && versionId > 0,
    }
  )
  return {
    version: data,
    isLoading,
    error,
  }
}

export function useFetchActionVersionByGuid(versionGuid?: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.actionVersion.getByGuid.useQuery(
    { id: versionGuid! },
    {
      enabled: user !== undefined && !!versionGuid,
    }
  )
  return {
    version: data,
    isLoading,
    error,
  }
}
