import { AnalyticsBrowser, type EventProperties, type Traits } from '@segment/analytics-next'
import { env } from '@/env.mjs'

export const analytics = new AnalyticsBrowser()

if (env.NEXT_PUBLIC_NODE_ENV === 'production') {
  analytics.load({ writeKey: env.NEXT_PUBLIC_SEGMENT_WRITE_KEY })
}

export const EVENT_NAMES = {
  // workspace level
  /////////////////////////////////////////////////////////////////////

  // workspace events
  workspace_created: 'Workspace Created',
  workspace_edited: 'Workspace Updated',
  workspace_switched: 'Workspace Switched',
  workspace_deleted: 'Workspace Deleted',
  workspace_transfered: 'Workspace Transfered',
  workspace_left: 'Workspace Left',
  // member events
  member_invited: 'Member Invited',
  member_created: 'Member Created',
  member_joined: 'Member Joined',
  member_updated: 'Member Updated',
  member_deleted: 'Member Deleted',
  //integrations events
  integration_created: 'Integration Created',
  // prompt
  prompt_prompt: 'Data Generation',
  // studio
  studio_prompt: 'Studio Generation',
  studio_error: 'Studio Error',
  studio_load: 'Studio Loaded',
  // tools // TBD SKILL
  skill_created: 'Skill Created',
  skill_edited: 'Skill Updated',
  skill_deleted: 'Skill Deleted',

  eval_created: 'Eval Created',
  eval_edited: 'Eval Updated',
  eval_deleted: 'Eval Deleted',
  eval_run: 'Eval Run',

  // model level
  default_model_saved: 'Default Model Saved',

  // app level
  /////////////////////////////////////////////////////////////////////

  // app events
  app_created: 'App Created',
  app_edited: 'App Updated',
  app_deleted: 'App Deleted',
  // action events
  action_created: 'Action Created',
  action_copied: 'Action Copied',
  action_moved: 'Action Moved',
  action_updated: 'Action Updated',
  action_renamed: 'Action Renamed',
  action_deleted: 'Action Deleted',

  context_created: 'Context Created',
  context_edited: 'Context Updated',
  context_deleted: 'Context Deleted',

  document_created: 'Document Created',
  document_edited: 'Document Updated',
  document_deleted: 'Document Deleted',

  // data generation, feedback, and correction
  prompt_review: 'Data Feedback',
  prompt_correction: 'Data Correction',
  signed_in: 'Signed In',
  api_key_created: 'API Key Created',
  api_key_deleted: 'API Key Deleted',

  workflow_created: 'Workflow Created',
  workflow_edited: 'Workflow Updated',
  workflow_deleted: 'Workflow Deleted',
  workflow_triggered: 'Workflow Triggered',
  chat_deleted: 'Chat Deleted',
  chat_created: 'Chat Created',
  chat_edited: 'Chat Updated',

  environment_created: 'Environment Created',
  environment_edited: 'Environment Updated',

  action_version_deployed: 'Action Version Deployed',
  action_version_updated: 'Action Version Updated',

  session_created: 'Session Created',
  session_deleted: 'Session Deleted',
  session_updated: 'Session Updated',

  data_imported: 'Data Imported',
  dataset_exported: 'Dataset Exported',
  dataset_deleted: 'Dataset Deleted',
  dataset_items_deleted: 'Dataset Items Deleted',
  dataset_created: 'Dataset Created',
  dataset_edited: 'Dataset Updated',

  data_generation: 'Data Generation',

  finetune_created: 'Finetune Created',

  curator_deleted: 'Curator Deleted',
  curator_edited: 'Curator Updated',

  // graveyard to be reviewed and removed
  /////////////////////////////////////////////////////////////////////
  // tool_created_from_integration: 'Skill Created From Integration', // should be attribute of tool_created // not used today
  // action_prompt: 'Action Generation', // generic action prompt generation // HANDLED IN ENGINE // not used today
}

export function track(name: string, properties?: EventProperties) {
  if (Object.values(EVENT_NAMES).includes(name)) {
    console.log(`tracked ${name}`)
    void analytics.track(name, properties)
  }
}

export function identify_and_group(
  id: string,
  email: string,
  name: string,
  slug: string | undefined,
  traits?: Traits
) {
  void analytics.identify(
    id,
    {
      name,
      email,
      traits,
    },
    () => {
      console.log('Segment identified user')
    }
  )
  const domain = email ? email.split('@')[1] : slug
  void analytics.group(
    domain,
    {
      traits,
    },
    () => {
      console.log('Segment identified group')
    }
  )
}
