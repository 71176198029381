import type { PropsWithChildren } from 'react'
import React, { useCallback, useEffect } from 'react'
import { useFeatureFlag } from '@/utils/featureFlags'

export const useDarkMode = () => {
  const { isDarkModeEnabled } = useFeatureFlag('darkMode')

  const setDarkMode = useCallback(
    (isDarkMode: boolean) => {
      if (isDarkMode) {
        if (!isDarkModeEnabled) {
          return
        }
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    },
    [isDarkModeEnabled]
  )

  return {
    setDarkMode,
  }
}

export const useSystemAppearanceMode = () => {
  const { setDarkMode } = useDarkMode()

  useEffect(() => {
    if (localStorage.theme) {
      if (localStorage.theme === 'dark') {
        setDarkMode(true)
      } else {
        setDarkMode(false)
      }
      return
    }

    const colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)')
    setDarkMode(colorSchemeQueryList.matches)
    colorSchemeQueryList.addEventListener('change', (e) => setDarkMode(e.matches))
  }, [setDarkMode])
}

export const ThemeMode: React.FC<PropsWithChildren> = ({ children }) => {
  useSystemAppearanceMode()

  return <>{children}</>
}
