import { api } from '@/utils'
import { useUser } from './user'

export function useFetchMembers(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspace.getMembers.useQuery(
    { workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    members: data,
    isLoading,
    error,
  }
}

export function useFetchCurrentAndDisabledMembers(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspace.getCurrentAndDisabledMembers.useQuery(
    { workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    members: data,
    isLoading,
    error,
  }
}
