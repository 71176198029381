import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const buttonVariants = cva(
  `
    inline-flex
    font-everett
    gap-1
    h-9
    items-center
    justify-center
    rounded-md
    shadow-sm
    transition-all
    ease-in-out
    text-white
    focus:shadow-focus
    focus-visible:shadow-focus
    focus-visible:outline-none

    disabled:cursor-not-allowed
  
    disabled:text-opacity-50
    
    dark:focus:shadow-focus-dark
    dark:focus-visible:shadow-focus-dark
    dark:disabled:text-grey-600
    dark:disabled:bg-grey-700
    dark:bg-grey-600
`,
  {
    variants: {
      variant: {
        primary: `
          bg-black
          disabled:hover:bg-black
          hover:bg-grey-800

          dark:bg-white
          dark:text-grey-900
          dark:hover:bg-grey-200
        `,
        secondary: `
          bg-grey-100
          ring-grey-300
          ring-1
          shadow-sm
          hover:bg-grey-200
          disabled:hover:bg-grey-100
          text-black

          dark:bg-grey-800
          dark:ring-grey-600
          dark:text-white
          dark:hover:bg-grey-700
        `,
        outline: `
          text-grey-800
          bg-white
          ring-1
          ring-grey-300
          ring-inset
          hover:ring-grey-400
          disabled:opacity-100
          disabled:hover:ring-grey-300
          dark:text-grey-50
          dark:ring-grey-700
          dark:hover:ring-grey-600
        `,
        'outline-hover': `
          bg-transparent
          text-grey-800
          ring-1
          ring-inset
          disabled:opacity-100
          disabled:hover:ring-grey-300
          ring-transparent
          transition
          drop-shadow-none
          shadow-none
          duration-200

          hover:shadow-sm
          hover:text-black
          hover:ring-grey-300
          hover:bg-white

          dark:text-grey-50
          dark:ring-grey-700
          dark:hover:ring-grey-600
          dark:hover:bg-black
      `,
        'outline-hover-destructive': `

          bg-transparent
          text-grey-800
          ring-1
          ring-inset
          disabled:opacity-100
          disabled:hover:ring-grey-300
          ring-transparent
          transition
          drop-shadow-none
          shadow-none
          duration-200

          hover:shadow-sm
          hover:text-red-700
          hover:ring-red-200
          hover:bg-white

          dark:text-red-50
          dark:ring-red-700
          dark:hover:ring-red-600
          dark:hover:bg-black
        `,
        ghost: `
          text-grey-500
          hover:text-grey-800
          hover:bg-grey-100
          shadow-none

          dark:text-grey-50
          dark:hover:bg-grey-700
        `,
        destructive: `
          bg-red-700
          ring-1
          ring-red-700
          hover:ring-red-700
          hover:bg-red-800
          hover:text-white
          text-white
        `,
      },
      size: {
        xs: `
          caption1
          font-medium
          p-1
        `,
        sm: `
          body3
          font-medium
          px-3
          p-1.5
          `,
        md: `
          body2
          font-medium
          py-2
          px-3
        `,
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'sm',
    },
  }
)

export type ButtonVariantProps = VariantProps<typeof buttonVariants>
