export function dateDiffInWeeks(dt2: Date, dt1: Date) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= 60 * 60 * 24 * 7
  return Math.abs(Math.round(diff))
}

export function difference2Parts(milliseconds: number) {
  const secs = Math.floor(Math.abs(milliseconds) / 1000)
  const mins = Math.floor(secs / 60)
  const hours = Math.floor(mins / 60)
  const days = Math.floor(hours / 24)
  const millisecs = Math.floor(Math.abs(milliseconds)) % 1000
  const multiple = (term: string, n: number) => (n !== 1 ? `${n} ${term}s` : `1 ${term}`)

  return {
    days: days,
    hours: hours % 24,
    hoursTotal: hours,
    minutesTotal: mins,
    minutes: mins % 60,
    seconds: secs % 60,
    secondsTotal: secs,
    milliSeconds: millisecs,
    get diffStr() {
      let result = 'a few moments'
      if (this.days > 0) {
        result = multiple(`day`, this.days)
      } else if (this.hours > 0) {
        result = multiple(`hour`, this.hours)
      } else if (this.minutes > 0) {
        result = multiple(`minute`, this.minutes)
      }
      return result + (milliseconds > 0 ? ' ago' : ' from now')
    },
    get diffStrMs() {
      return `${this.diffStr.replace(` and`, `, `)} and ${multiple(
        `millisecond`,
        this.milliSeconds
      )}`
    },
  }
}

export function dateAgo(date: Date) {
  const now = new Date()

  // Convert both dates to UTC by adding the timezone offset
  const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000)
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000)

  // Calculate the difference
  const result = difference2Parts(utcNow.getTime() - utcDate.getTime())

  // Return the human-readable string
  return result.diffStr
}

export const monthLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function getMonths() {
  const d = new Date()
  const currentMonth = d.getMonth() + 1
  const data = []
  for (let i = currentMonth; i < currentMonth + 12; i++) {
    const year = d.getFullYear() + Math.floor(i / 12) - 1
    const month = monthLabels[i % 12]
    const date = new Date(year, i % 12, 1)
    data.push({
      month,
      year,
      date, // for sorting
    })
  }
  const sortedData = data.sort((a, b) => a.date.getTime() - b.date.getTime())
  return sortedData
}

export function getWeeks() {
  const d = new Date()
  const currentWeek = Math.floor(d.getDate() / 7)
  const data = []
  for (let i = currentWeek; i < currentWeek + 52; i++) {
    const year = d.getFullYear() + Math.floor(i / 52)
    const weekOfYear = i % 52
    const date = new Date(year, d.getMonth(), weekOfYear * 7 + 1)
    data.push({
      week: weekOfYear + 1,
      year,
      date, // for sorting
    })
  }
  const sortedData = data.sort((a, b) => a.date.getTime() - b.date.getTime())
  return sortedData
}

export function formatDataInTimezone(date: Date) {
  // Get the timezone offset in minutes
  const timezoneOffset = date.getTimezoneOffset()

  // Create a new Date object adjusted for the timezone offset
  const newDate = new Date(date.getTime() + timezoneOffset * 60 * 1000)

  // Calculate the hours offset from UTC
  const hoursOffset = timezoneOffset / 60

  // Adjust the hours of the new Date object to match the specified timezone
  newDate.setHours(newDate.getHours() - hoursOffset)

  return newDate
}
