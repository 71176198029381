import { CommandDialog } from './Dialog'
import { CommandEmpty } from './Empty'
import { CommandGroup } from './Group'
import { CommandInput, CommandInputV2 } from './Input'
import { CommandItem } from './Item'
import { CommandList } from './List'
import { CommandLoading } from './Loading'
import { CommandRoot } from './Root'
import { CommandSeparator } from './Separator'
import { CommandShortcut } from './Shortcut'

const Command = {
  Root: CommandRoot,
  Dialog: CommandDialog,
  Input: CommandInput,
  InputV2: CommandInputV2,
  List: CommandList,
  Empty: CommandEmpty,
  Group: CommandGroup,
  Separator: CommandSeparator,
  Item: CommandItem,
  Shortcut: CommandShortcut,
  Loading: CommandLoading,
}

export { Command }
