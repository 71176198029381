import { useCurrentWorkspace, useWorkspaceFeaturesEnabled } from '@/common/hooks'

export const featureFlags = {
  workflows: 'workflows',
  assist: 'assist',
  darkMode: 'darkMode',
  advancedLLM: 'advancedLLM',
  evals: 'evals',
  compareMode: 'compareMode',
  errors: 'errors',
  rerankLlmTopN: 'rerankLlmTopN',
}

function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const useFeatureFlag = <T extends keyof typeof featureFlags>(
  featureFlag: T
): {
  [key in `is${Capitalize<T>}Enabled`]: boolean
} & { isLoading: boolean } => {
  const { workspace } = useCurrentWorkspace()
  const { featuresEnabled, isLoading } = useWorkspaceFeaturesEnabled(workspace?.id)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    isLoading,
    [`is${capitalizeFirstLetter(featureFlag)}Enabled`]: featuresEnabled?.includes(featureFlag),
  } as {
    [key in `is${Capitalize<T>}Enabled`]: boolean
  } & { isLoading: boolean }
}
