import * as SelectPrimitive from '@radix-ui/react-select'
import { SelectContent } from './Content'
import { SelectField } from './Field'
import { SelectItem } from './Item'
import { SelectLabel } from './Label'
import { SelectSeparator } from './Separator'
import { SelectTrigger } from './Trigger'

const Select = {
  Root: SelectPrimitive.Root,
  Group: SelectPrimitive.Group,
  Value: SelectPrimitive.Value,
  Trigger: SelectTrigger,
  Content: SelectContent,
  Label: SelectLabel,
  Item: SelectItem,
  Separator: SelectSeparator,
  Field: SelectField,
}

export { Select }
