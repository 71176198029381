import { useMemo } from 'react'
import { useStudioFormikContext } from '@/client/containers/views/Studio/components/Formik/hooks/useStudioFormikContext'
import { useFetchModelProviderName } from '@/common/hooks'

export const MODELS_WITH_STRUCTURED_OUTPUT = [
  'gpt-4o-2024-08-06',
  'gpt-4o-mini',
  'gpt-4o-mini-2024-07-18',
]
export const MODELS_WITH_JSON_MODE = [
  'gpt-4o-2024-08-06',
  'gpt-4o',
  'gpt-4o-2024-05-13',
  'gpt-4o-mini',
  'gpt-4o-mini-2024-07-18',
  'gpt-4-turbo',
  'gpt-4-turbo-preview',
  'gpt-4-0125-preview',
  'gpt-4-turbo-2024-04-09',
  'gpt-4-1106-preview',
  'gpt-3.5-turbo-1106',
  'gpt-3.5-turbo-0125',
  'gpt-3.5-turbo',
  'mistral-large-latest',
  'open-mistral-nemo',
]

export const useIsStructuredOutputSupported = () => {
  const { values: formikState } = useStudioFormikContext()
  const { modelName, workspaceModelProviderId } = formikState
  const { provider } = useFetchModelProviderName(parseInt(workspaceModelProviderId))

  return useMemo(() => {
    return Boolean(
      MODELS_WITH_STRUCTURED_OUTPUT.some((model) => modelName.includes(model)) &&
        provider?.provider?.name === 'OpenAI'
    )
  }, [modelName, provider])
}

export const useIsJsonModeSupported = () => {
  const { values: formikState } = useStudioFormikContext()

  return useMemo(() => {
    const { modelName } = formikState
    return Boolean(MODELS_WITH_JSON_MODE.some((model) => modelName.includes(model)))
  }, [formikState])
}
