import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Button } from '../Button'
import type { ButtonProps } from '../Button/types'
import { Dialog } from '../Dialog'

interface ConfirmActionDialogProps {
  onConfirm: () => void
  header?: string
  confirmationMessage?: string
  variant?: ButtonProps['variant']
  children?: React.ReactNode
}

const ConfirmActionDialog: React.FC<ConfirmActionDialogProps> = ({
  onConfirm,
  header,
  confirmationMessage,
  variant,
  children,
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <div>
      <div
        onClick={() => {
          setOpen(true)
        }}
      >
        {children}
      </div>
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Content className="max-w-md overflow-hidden bg-white ring-1 ring-grey-300/50">
          <Dialog.Header>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="w-full sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-lg font-medium leading-6 text-grey-900">
                    {header || `Confirm action`}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-grey-700">
                      {confirmationMessage || `Are you sure?`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-between rounded-b-lg border-t border-grey-200 bg-grey-50 px-6 py-5 sm:flex">
              <Button variant="outline" onClick={() => setOpen(false)}>
                Close
              </Button>
              <Button
                variant={variant}
                onClick={() => {
                  onConfirm()
                  setOpen(false)
                }}
              >
                Confirm
              </Button>
            </div>
          </Dialog.Header>
        </Dialog.Content>
      </Dialog.Root>
    </div>
  )
}

export { ConfirmActionDialog }
