export const nangoProviders = [
  {
    name: 'Asana',
    configKey: 'asana',
    imagePath: 'asana.png',
    description:
      'Task management and collaboration tool for teams. Features include project and task assignment, progress tracking, and shared team calendars.',
    api_docs: 'https://raw.githubusercontent.com/Asana/developer-docs/master/defs/asana_oas.yaml',
  },
  {
    name: 'Airtable',
    configKey: 'airtable',
    imagePath: 'airtable.png',
    description:
      'Collaborative workspace platform that combines the flexibility of a spreadsheet with the power of a database.',
  },
  {
    name: 'Github',
    configKey: 'github',
    imagePath: 'github.png',
    description:
      'Version control repository hosting service with support for Git. Includes code review tools, issue tracking, and integration with other development tools.',
    api_docs:
      'https://raw.githubusercontent.com/github/rest-api-description/main/descriptions-next/ghes-3.0/ghes-3.0.yaml',
  },

  {
    name: 'Google',
    configKey: 'google',
    imagePath: 'google.png',
    description:
      'Email client with customizable filters, labels, and integrations with other Google services.',
  },
  {
    name: 'Zendesk',
    configKey: 'zendesk',
    api_docs: 'https://raw.githubusercontent.com/ItamarBittton/zendesk-openapi/main/openapi.yaml', //these are not official
    imagePath: 'zendesk.png',
    description:
      'Customer support and help desk software with features for ticket management, knowledge base creation, and reporting.',
  },
  {
    name: 'Intercom',
    configKey: 'intercom',
    imagePath: 'intercom.png',
    api_docs:
      'https://raw.githubusercontent.com/intercom/Intercom-OpenAPI/main/descriptions/2.9/api.intercom.io.yaml',
    description:
      'Customer messaging platform with features for live chat, help center, and automation.',
  },
  {
    name: 'Jira',
    configKey: 'jira',
    imagePath: 'jira.png',
    api_docs: 'https://developer.atlassian.com/cloud/jira/platform/swagger-v3.v3.json',
    description:
      'Team workspace and collaboration tool for creating, sharing, and collaborating on documents, ideas, and projects. Features include customizable templates, real-time collaboration, and version history.',
  },
  {
    name: 'Notion',
    configKey: 'notion',
    imagePath: 'notion.png',
    api_docs:
      'https://raw.githubusercontent.com/klu-ai/integration_api_specs/main/notion_api_spec.yaml?token=GHSAT0AAAAAACB43FRAYBVAG63UGZJSFB4SZDE3SSQ',
    description:
      'All-in-one workspace for notes, tasks, wikis, and databases. Features include customizable templates, real-time collaboration, and integration with other productivity tools.',
  },
  {
    name: 'Slack',
    configKey: 'slack',
    imagePath: 'slack.png',
    api_docs:
      'https://raw.githubusercontent.com/slackapi/slack-api-specs/master/web-api/slack_web_openapi_v2.json',
    description:
      'Collaboration and communication platform for teams with features for chat, file sharing, and app integration.',
  },
  {
    name: 'Teams',
    configKey: 'microsoft-teams',
    imagePath: 'teams.png',
    description:
      'Collaboration and communication platform for teams with features for chat, video meetings, and file sharing.',
  },
  {
    name: 'Zoom',
    configKey: 'zoom',
    imagePath: 'zoom.png',
    api_docs: 'https://github.com/klu-ai/integration_api_specs/raw/main/ZoomMeetingAPISpec.json',
    description:
      'Video conferencing and web conferencing platform with features for video meetings, webinars, and virtual events.',
  },
  // {
  //   name: 'Snowflake',
  //   configKey: 'snowflake',
  //   imagePath: 'snowflake.png',
  //   api_docs:
  //     'https://raw.githubusercontent.com/snowflakedb/snowflake-api-openapi/master/snowflake_openapi.yaml',
  //   description:
  //     'Cloud-based data warehouse with features for data analytics, data integration, and data sharing.',
  // },
  // {
  //   name: 'Pagerduty',
  //   configKey: 'pagerduty',
  //   api_docs: "https://raw.githubusercontent.com/PagerDuty/api-schema/main/reference/REST/openapiv3.json",
  //   imagePath: 'pagerduty.png',
  //   description:
  //     'Incident management platform for IT and DevOps teams. Includes real-time alerts, on-call scheduling, and incident response tools.',
  // },
  // {
  //   name: 'Gitlab',
  //   configKey: 'gitlab',
  //   imagePath: 'gitlab.png',
  //   api_docs: 'https://gitlab.com/gitlab-org/gitlab/-/raw/master/doc/api/openapi/openapi.yaml',
  //   description:
  //     'Version control repository hosting service with support for Git. Includes continuous integration and deployment tools, code review, and issue tracking.',
  // },
  // {
  //   name: 'Clickup',
  //   configKey: 'clickup',
  //   imagePath: 'clickup.png',
  //   description:
  //     'All-in-one productivity platform with task management, project management, and productivity features. Includes customizable workflows, calendars, and time tracking.',
  // },
  // {
  //   name: 'Front',
  //   configKey: 'front',
  //   imagePath: 'frontapp.png',
  //   api_docs: "https://raw.githubusercontent.com/frontapp/front-api-specs/main/core-api/core-api.json",
  //   description:
  //     'Customer communication platform with features for email management, collaboration, and automation.',
  // },
]

export const apiDocsLookup = (configKey: string) => {
  // find the provider with the matching configKey
  const provider = nangoProviders.find((provider) => provider.configKey === configKey)
  // if the provider exists, return the api_docs
  if (provider) {
    return provider.api_docs
  }
}
