import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { DropdownMenuCheckboxItem } from './CheckboxItem'
import { DropdownMenuContent } from './Content'
import { DropdownMenuItem } from './Item'
import { DropdownMenuLabel } from './Label'
import { DropdownMenuRadioItem } from './RadioItem'
import { DropdownMenuSeparator } from './Separator'
import { DropdownMenuShortcut } from './Shortcut'
import { DropdownMenuSubContent } from './SubContent'
import { DropdownMenuSubTrigger } from './SubTrigger'

const DropdownMenu = {
  Root: DropdownMenuPrimitive.Root,
  Trigger: DropdownMenuPrimitive.Trigger,
  Group: DropdownMenuPrimitive.Group,
  Portal: DropdownMenuPrimitive.Portal,
  Sub: DropdownMenuPrimitive.Sub,
  RadioGroup: DropdownMenuPrimitive.RadioGroup,
  SubTrigger: DropdownMenuSubTrigger,
  SubContent: DropdownMenuSubContent,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  Shortcut: DropdownMenuShortcut,
  CheckboxItem: DropdownMenuCheckboxItem,
  RadioItem: DropdownMenuRadioItem,
  Label: DropdownMenuLabel,
  Separator: DropdownMenuSeparator,
}

export { DropdownMenu }
