import * as TabsPrimitive from '@radix-ui/react-tabs'
import { SwitcherContent } from './Content'
import { SwitcherList } from './List'
import { SwitcherTrigger } from './Trigger'

const Switcher = {
  Root: TabsPrimitive.Root,
  List: SwitcherList,
  Trigger: SwitcherTrigger,
  Content: SwitcherContent,
}

export { Switcher }
