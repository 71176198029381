import * as TabsPrimitive from '@radix-ui/react-tabs'
import React from 'react'
import { cn } from '@/client/utils'

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      `
        inline-flex
        items-center
        justify-center
        gap-7
        text-sm
      `,
      className
    )}
    {...props}
  />
))

TabsList.displayName = TabsPrimitive.List.displayName

export { TabsList }
