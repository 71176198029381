import { cn } from '@/client/utils'
import type { SkeletonProps } from './types'

function Skeleton({ className, ...props }: SkeletonProps) {
  return (
    <div
      className={cn('h-3 animate-pulse rounded bg-grey-400/50 dark:bg-grey-100/[.36]', className)}
      {...props}
    />
  )
}

export { Skeleton }
