import { api } from '@/utils'
import { useUser } from './user'

export const useFetchBatchJobs = (batchJobIds: number[]) => {
  const { user } = useUser()
  const { data, isLoading, error } = api.batchJob.getByIds.useQuery(
    { ids: batchJobIds },
    {
      enabled: user !== undefined,
    }
  )
  return {
    batchJobs: data,
    isLoading,
    error,
  }
}
