import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { AccordionContent } from './Content'
import { AccordionItem } from './Item'
import { AccordionTrigger } from './Trigger'

const Accordion = {
  Root: AccordionPrimitive.Root,
  Item: AccordionItem,
  Trigger: AccordionTrigger,
  Content: AccordionContent,
}

export { Accordion }
