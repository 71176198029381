import { Command as CommandPrimitive } from 'cmdk'
import React from 'react'
import type { CommandLoadingProps } from './types'

const CommandLoading = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  CommandLoadingProps
>(({ ...props }, ref) => (
  <div className={'p-2'}>
    <CommandPrimitive.Loading ref={ref} {...props} />
  </div>
))

CommandLoading.displayName = CommandPrimitive.Separator.displayName

export { CommandLoading }
