import { Command as CommandPrimitive } from 'cmdk'
import React from 'react'
import { cn } from '@/client/utils'
import type { CommandListProps } from './types'

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  CommandListProps
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn('no-scrollbar max-h-[300px] overflow-y-auto overflow-x-hidden', className)}
    {...props}
  />
))

CommandList.displayName = CommandPrimitive.List.displayName

export { CommandList }
