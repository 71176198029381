import { get_encoding } from '@dqbd/tiktoken'

const tiktoken = get_encoding('cl100k_base')

export const decodeToken = (text: string) => {
  const encode = tiktoken.encode(text)
  const decodedArr = Array.from(encode).map((token) => {
    const uint = tiktoken.decode_single_token_bytes(token)
    const str = new TextDecoder().decode(uint)
    return [str, token]
  })

  return { decodedArr, encode }
}

export const useDecodedToken = () => {
  const getDecodedToken = (text: string) => decodeToken(text)

  return { getDecodedToken }
}

const useTokenizer = (text: string) => {
  const { getDecodedToken } = useDecodedToken()

  const { decodedArr, encode } = getDecodedToken(text)

  return {
    encodedTokens: encode,
    tokenCount: decodedArr.length,
    decodedTokens: decodedArr,
    getTokenColor: (number: number) => {
      const goldenRatioConjugate = 0.618033988749895

      /* Constants for a linear congruential generator (LCG) */
      const a = 1664525
      const c = 1013904223
      const m = Math.pow(2, 32)

      /* Generate a pseudorandom number using the LCG. */
      const pseudorandom = (a * number + c) % m

      /* Compute a hue value using the golden angle.*/
      const hue = ((pseudorandom * goldenRatioConjugate) % 1) * 360

      /* Allow saturation to vary between 60% and 100%.*/
      const s = 60 + (pseudorandom % 21)

      /* Allow lightness to vary between 70% and 90%.*/
      const l = 70 + (pseudorandom % 21)

      /* Convert HSL to RGB*/
      const h = hue / 360
      const sNorm = s / 100
      const lNorm = l / 100

      const chroma = (1 - Math.abs(2 * lNorm - 1)) * sNorm
      const x = chroma * (1 - Math.abs(((h * 6) % 2) - 1))
      const mNorm = lNorm - chroma / 2

      let r = 0,
        g = 0,
        b = 0

      if (0 <= h && h < 1 / 6) {
        r = chroma
        g = x
        b = 0
      } else if (1 / 6 <= h && h < 1 / 3) {
        r = x
        g = chroma
        b = 0
      } else if (1 / 3 <= h && h < 1 / 2) {
        r = 0
        g = chroma
        b = x
      } else if (1 / 2 <= h && h < 2 / 3) {
        r = 0
        g = x
        b = chroma
      } else if (2 / 3 <= h && h < 5 / 6) {
        r = x
        g = 0
        b = chroma
      } else if (5 / 6 <= h && h < 1) {
        r = chroma
        g = 0
        b = x
      }

      r = Math.round((r + mNorm) * 255)
      g = Math.round((g + mNorm) * 255)
      b = Math.round((b + mNorm) * 255)

      /* Alpha component, here we'll just use 1 for full opacity */
      const alpha = 1

      return `rgba(${r}, ${g}, ${b}, ${alpha})`
    },
  }
}

export default useTokenizer
