import { CalendarIcon } from '@heroicons/react/24/outline'
import { format } from 'date-fns'
import * as React from 'react'
import type { DateRange } from 'react-day-picker'
import { cn } from '@/client/utils'
import { Button } from '../Button'
import { Calendar } from '../Calendar'
import { Popover } from '../Popover'

export function DatePickerWithRange({
  className,
  dateRange,
  setDate,
}: React.HTMLAttributes<HTMLDivElement> & {
  dateRange: DateRange | undefined
  setDate: (range: DateRange | undefined) => void
}) {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover.Root>
        <Popover.Trigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal font-inter',
              !dateRange && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateRange?.from ? (
              dateRange?.to ? (
                <>
                  {format(dateRange?.from, 'LLL dd, y')} - {format(dateRange?.to, 'LLL dd, y')}
                </>
              ) : (
                format(dateRange?.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </Popover.Trigger>
        <Popover.Content className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}
