import { api } from '@/utils'
import { useUser } from './user'

export function useFetchModels() {
  const { user } = useUser()
  const { data, isLoading, error } = api.model.getAll.useQuery(undefined, {
    enabled: user !== undefined,
  })
  return {
    models: data,
    isLoading,
    error,
  }
}

export function useFetchModelsForProvider(workspaceModelProviderId?: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.model.getAllForProvider.useQuery(
    { workspaceModelProviderId: workspaceModelProviderId! },
    {
      enabled: user !== undefined && Boolean(workspaceModelProviderId),
    }
  )
  return {
    models: data,
    isLoading,
    error,
  }
}

export function useFetchModel(modelId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.model.get.useQuery(
    { id: modelId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    model: data,
    isLoading,
    error,
  }
}

export function useFetchModelProvider(providerId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspaceModelProvider.get.useQuery(
    {
      id: providerId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    provider: data,
    isLoading,
    error,
  }
}

export function useFetchModelProviderName(providerId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspaceModelProvider.getProviderName.useQuery(
    {
      id: providerId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    provider: data,
    isLoading,
    error,
  }
}

export function useFetchModelProviders(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspaceModelProvider.getAll.useQuery(
    {
      workspaceId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    providers: data,
    isLoading,
    error,
  }
}

export function useFetchModelProviderNames(
  workspaceSlug: string,
  workspaceModelProviderId?: number
) {
  const { user } = useUser()
  const { data, isLoading, error } = api.model.getProviderModelNames.useQuery(
    {
      workspaceSlug,
      workspaceModelProviderId: workspaceModelProviderId!,
    },
    {
      enabled: user !== undefined && Boolean(workspaceModelProviderId),
    }
  )
  return {
    providerNames: data,
    isLoading,
    error,
  }
}

export function useFetchWorkspaceModelProviders(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspaceModelProvider.getAll.useQuery(
    {
      workspaceId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    providers: data,
    isLoading,
    error,
  }
}

export function useFetchDefaultModelProvider(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspaceModelProvider.getDefault.useQuery(
    {
      workspaceId,
    },
    {
      enabled: user !== undefined,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )
  return {
    provider: data,
    isLoading,
    error,
  }
}

export function useFetchDefaultModelForProvider(workspaceModelProviderId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.model.getDefaultForProvider.useQuery(
    {
      workspaceModelProviderId,
    },
    {
      enabled: user !== undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )
  return {
    model: data,
    isLoading,
    error,
  }
}
