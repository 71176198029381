import { api } from '@/utils'
import { useUser } from './user'

export function useFetchInvites(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.invite.getAll.useQuery(
    { workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    invites: data,
    isLoading,
    error,
  }
}
