import { cva } from 'class-variance-authority'

export const textAreaVariants = cva(
  `
    border-0
    font-regular
    text-sm
    w-full
    rounded-md
    border
    bg-transparent
    break-words
    p-4
    text-grey-800
    placeholder:text-grey-300
    placeholder:text-grey-500
    focus:ring-0
    disabled:text-grey-300
    dark:text-grey-50
    dark:placeholder:text-grey-600
    dark:disabled:text-grey-600
  `,
  {
    variants: {
      variant: {
        primary: '',
        noBorder: `
          border-0 p-2
        `,
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
)
