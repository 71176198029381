import * as PopoverPrimitive from '@radix-ui/react-popover'
import { PopoverContent } from './Content'
import { PopoverTrigger } from './Trigger'

const Popover = {
  Root: PopoverPrimitive.Root,
  Trigger: PopoverTrigger,
  Content: PopoverContent,
  Portal: PopoverPrimitive.Portal,
}

export { Popover }
