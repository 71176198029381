import * as SwitchPrimitive from '@radix-ui/react-switch'
import React from 'react'
import { cn } from '@/client/utils'
import type { SwitchProps } from './types'

/**
 * @todo
 * Check when the Figma is finished.
 * Pending dark mode.
 */

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitive.Root>, SwitchProps>(
  ({ className, ...props }, ref) => {
    return (
      <SwitchPrimitive.Root
        className={cn(
          `
            data-[state=checked]:hover:bg-primary-dark
            peer
            inline-flex
            h-5
            w-9
            shrink-0
            cursor-pointer
            items-center
            rounded-full
            border-2
            border-transparent
            outline-none
            transition-colors
            focus-visible:shadow-focus
            disabled:!bg-grey-100
            data-[state=checked]:bg-primary
            data-[state=unchecked]:bg-grey-300
            data-[state=unchecked]:hover:bg-grey-400
            data-[state=unchecked]:focus-visible:bg-grey-400
            
            dark:disabled:!bg-grey-700
            dark:data-[state=unchecked]:bg-grey-800
            dark:data-[state=unchecked]:hover:bg-grey-700
          `,
          className
        )}
        {...props}
        ref={ref}
      >
        <SwitchPrimitive.Thumb
          className={cn(
            `
              pointer-events-none
              block
              h-3.5
              w-3.5
              rounded-full
              bg-grey-50
              shadow-lg
              ring-0
              transition-transform
              data-[state=checked]:translate-x-[1.125rem]
              data-[state=unchecked]:translate-x-0
            `
          )}
        />
      </SwitchPrimitive.Root>
    )
  }
)

Switch.displayName = SwitchPrimitive.Root.displayName

export { Switch }
