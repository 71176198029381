import * as DialogPrimitive from '@radix-ui/react-dialog'
import { DialogContent } from './Content'
import { DialogDescription } from './Description'
import { DialogFooter } from './Footer'
import { DialogHeader } from './Header'
import { DialogTitle } from './Title'

/**
 * @todo Pending to review with figma when Dialog is ready
 */

const Dialog = {
  Root: DialogPrimitive.Root,
  Trigger: DialogPrimitive.Trigger,
  Content: DialogContent,
  Header: DialogHeader,
  Footer: DialogFooter,
  Title: DialogTitle,
  Description: DialogDescription,
  Portal: DialogPrimitive.Portal,
}

export { Dialog }
