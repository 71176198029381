import hljs from 'highlight.js/lib/core'
import type { FC } from 'react'
import hbrs from '@/utils/handlebars'
import kluHandlebarsLanguage from './kluHandlebarsLanguage.mjs'

hljs.registerLanguage('handlebars', kluHandlebarsLanguage)

export const HighlightVariables: FC<{ value: string }> = ({ value }) => {
  let highlightedValue = hbrs.escapeExpression(value)
  if (value.includes('{{')) {
    highlightedValue = hljs.highlight(value, { language: 'handlebars' }).value
  }

  return <div className="language-hbs" dangerouslySetInnerHTML={{ __html: highlightedValue }}></div>
}
