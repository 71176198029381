import { Command as CommandPrimitive } from 'cmdk'
import React from 'react'
import { cn } from '@/client/utils'
import type { CommandRootProps } from './types'

const CommandRoot = React.forwardRef<React.ElementRef<typeof CommandPrimitive>, CommandRootProps>(
  ({ className, ...props }, ref) => (
    <CommandPrimitive
      ref={ref}
      className={cn(
        `
          flex
          h-full
          w-full
          flex-col
          overflow-hidden
          rounded-md
          bg-white
          text-grey-800
          dark:border-none
          dark:bg-grey-900
          dark:text-grey-50
        `,
        className
      )}
      {...props}
    />
  )
)

CommandRoot.displayName = CommandPrimitive.displayName

export { CommandRoot }
