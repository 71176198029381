import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { FieldMessage, Icon } from '@/client/components'
import { cn } from '@/client/utils'
import type { InputProps } from './types'

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      hideLabel,
      prefix,
      icon,
      value = '',
      hasError,
      message,
      infoText,
      endIcon: EndIcon,
      ...props
    },
    ref
  ) => {
    const { id, disabled } = props

    return (
      <div className={cn('relative', className)}>
        <label
          className={cn(
            'body2 mb-1.5 block font-medium',
            disabled ? 'text-grey-300 dark:text-grey-600' : 'text-grey-800',
            { 'sr-only': hideLabel }
          )}
          htmlFor={id}
        >
          {label}
        </label>

        <div
          id={'wrapper'}
          className={cn(
            'relative flex rounded-md border bg-white',
            hasError
              ? 'border-error focus-within:border-error'
              : 'border-grey-300 focus-within:border-grey-400 focus-within:ring-grey-400 dark:border-grey-600 dark:focus-within:border-white',
            { 'border-grey-100 bg-grey-100 dark:border-grey-700 dark:bg-grey-700': disabled }
          )}
        >
          {prefix ? (
            <div
              id="prefix"
              className={cn(
                'flex items-center border-r px-3 py-1 text-sm',
                hasError ? 'border-error' : 'border-grey-300 dark:border-grey-600',
                disabled ? 'text-grey-300 dark:text-grey-600' : 'text-grey-500'
              )}
            >
              {prefix}
            </div>
          ) : null}

          <div id={'wrapper-child'} className="flex h-9 grow items-center px-3 py-1">
            {icon ? (
              <div
                className={cn(
                  'relative flex grow items-center gap-2 pr-2',
                  disabled ? 'text-grey-300 dark:text-grey-600' : 'text-grey-800 dark:text-grey-50'
                )}
              >
                <Icon size="md" component={icon} />
              </div>
            ) : null}

            <input
              className={cn(
                `
                border-0
                bg-transparent
                p-0
                text-sm
                text-grey-800
                outline-none
                placeholder:text-xs
                placeholder:text-grey-500
                focus:ring-0
                disabled:text-grey-300
                dark:text-grey-50
                dark:placeholder:text-grey-600
                dark:disabled:text-grey-600
                w-full
              `,
                className
              )}
              ref={ref}
              {...props}
              onChange={(e) => {
                if (Number(e.target.value) < Number(props.min)) return
                if (Number(e.target.value) > Number(props.max)) return
                props.onChange?.(e)
              }}
              value={value}
            />

            <div
              className={cn(
                'relative flex grow items-center gap-2',
                disabled ? 'text-grey-300 dark:text-grey-600' : 'text-grey-800 dark:text-grey-50'
              )}
            >
              {infoText ? <Icon size="md" component={QuestionMarkCircleIcon} /> : null}

              {EndIcon ? <EndIcon /> : null}
            </div>
          </div>
        </div>

        {message ? (
          <FieldMessage message={message} disabled={disabled} hasError={hasError} />
        ) : null}
      </div>
    )
  }
)

Input.displayName = 'Input'

export { Input }
