import type { DatasetItemWithData } from '@/server/api/routers/datasetItem'
import { api } from '@/utils'
import { useUser } from './user'

export function useFetchDatasetItems({
  datasetId,
  page,
  perPage,
}: {
  datasetId: number
  page: number
  perPage: number
}) {
  const { user } = useUser()
  const { data, isLoading, error } = api.datasetItem.getAll.useQuery(
    { datasetId, page, perPage },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    total: data && data.total,
    datasetItems: data && data.datasetItems,
    isLoading,
    error,
  }
}

export function useFetchDatasetItemByGuid({ guid }: { guid: string }) {
  const { user } = useUser()

  const { data, isLoading, error } = api.datasetItem.getByGuid.useQuery(
    { guid },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    datasetItem: data as DatasetItemWithData | undefined,
    isLoading,
    error,
  }
}
