import type { EvalDataType } from '@prisma/client'
import { api } from '@/utils'

export function useFetchEvalType({ evalTypeId }: { evalTypeId: number }) {
  const { data, isLoading, error } = api.evalType.get.useQuery(
    {
      id: evalTypeId,
    },
    {
      refetchOnWindowFocus: false,
    }
  )
  return {
    evalType: data,
    isLoading,
    error,
  }
}

export function useFetchEvalTypes({ dataType }: { dataType?: EvalDataType }) {
  const { data, isLoading, error } = api.evalType.getAll.useQuery(
    {
      dataType,
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  return {
    evalTypes: data,
    isLoading,
    error,
  }
}

export function useFetchEvalsOnEvalTypesForEval({ evalId }: { evalId: number }) {
  const { data, isLoading, error } = api.evalsOnEvalTypes.getAllForEval.useQuery(
    {
      id: evalId,
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  return {
    evalsOnEvalTypes: data,
    isLoading,
    error,
  }
}
