import { api } from '@/utils'
import { useUser } from './user'

export function useFetchConnections(workspaceGuid: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.integration.getConnections.useQuery(
    { workspaceGuid },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    connections: data,
    isLoading,
    error,
  }
}

export function useFetchIntegrations(workspaceGuid: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.integration.getIntegrations.useQuery(
    { workspaceGuid },
    {
      enabled: user !== undefined,
      refetchOnWindowFocus: false,
    }
  )
  return {
    integrations: data,
    isLoading,
    error,
  }
}
