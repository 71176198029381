import * as TabsPrimitive from '@radix-ui/react-tabs'
import React from 'react'
import { cn } from '@/client/utils'

const SwitcherTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      `
        inline-flex
        items-center
        justify-center
        whitespace-nowrap
        rounded
        px-3
        py-1.5
        font-medium
        text-grey-400
        text-grey-500
        hover:text-grey-950 
        focus-visible:outline-none
        disabled:pointer-events-none
        data-[state=active]:bg-grey-50
        data-[state=active]:text-grey-950
        data-[state=active]:shadow-light-sm
        dark:hover:text-grey-50
        data-[state=active]:dark:bg-grey-900
        data-[state=active]:dark:text-grey-50
      `,
      className
    )}
    {...props}
  />
))

SwitcherTrigger.displayName = TabsPrimitive.Trigger.displayName

export { SwitcherTrigger }
