/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { Change } from 'diff'
import { diffChars } from 'diff'
import type { ChangeEvent } from 'react'

export const getDiffChars = (originalValue: string, newValue: string) => {
  return diffChars(originalValue, newValue)
}

export const wroteDoubleBrackets = (differences: Change[]) => {
  return (
    differences[0]?.count &&
    differences[0]?.value.endsWith('{') &&
    differences[1]?.added &&
    differences[1]?.value.endsWith('{')
  )
}

export const isCursorWithinDoubleBrackets = (ref: HTMLTextAreaElement | null) => {
  if (!ref) return false
  const cursorPosition = ref.selectionStart || 0
  const { value: text } = ref

  const maxSteps = 30

  // Check to the left
  let leftCount = 0
  for (let i = cursorPosition; i >= 2 && leftCount < maxSteps; i--) {
    if (text[i - 1] === '}') {
      return false
    } else if (text[i - 1] === '{' && text[i - 2] === '{') {
      return true // Found "{{" to the left
    }
    leftCount++
  }

  return false
}

export const addEndingVariableBrackets = ({
  e,
  cursorPosition,
  ref,
}: {
  e: ChangeEvent<HTMLTextAreaElement>
  cursorPosition: number
  ref: HTMLTextAreaElement | null
}) => {
  e.target.value =
    e.target.value.slice(0, cursorPosition) + '}}' + e.target.value.slice(cursorPosition)

  if (ref) {
    ref.selectionStart = cursorPosition
    ref.selectionEnd = cursorPosition
  }
}
