import type { IconSizeConfig } from './types'

export const ICON_SIZE_CONFIG: IconSizeConfig = {
  xxs: 2, // 0.5rem
  xs: 3, // 0.75rem
  sm: 4, // 1rem
  md: 5, // 1.25rem
  lg: 6, // 1.5rem
  xl: 7, // 1.75rem
  xxl: 8, // 2rem
}
