// https://stackoverflow.com/a/73748435
import type { ForwardedRef } from 'react'
import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useForwardRef = <T>(ref: ForwardedRef<T>, initialValue: any = null) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const targetRef = useRef<T>(initialValue)

  useEffect(() => {
    if (!ref) return

    if (typeof ref === 'function') {
      ref(targetRef.current)
    } else {
      ref.current = targetRef.current
    }
  }, [ref])

  return targetRef
}
