import { api } from '@/utils'
import { useUser } from './user'

export function useFetchWorkflows(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workflow.getAll.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    workflows: data,
    isLoading,
    error,
  }
}

export function useFetchWorkflow(workflowId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workflow.get.useQuery(
    { id: workflowId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    workflow: data,
    isLoading,
    error,
  }
}

export function useFetchWorkflowBySlug(slug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workflow.getBySlug.useQuery(
    { slug },
    {
      enabled: user !== undefined,
    }
  )
  return {
    workflow: data,
    isLoading,
    error,
  }
}

export function useFetchPublicWorkflow(workflowSlug: string) {
  const { data, isLoading, error } = api.publicAction.getPublicWorkflow.useQuery({
    slug: workflowSlug,
  })
  return {
    workflow: data,
    isLoading,
    error,
  }
}

export function useFetchWorkflowSchedule(workflowId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workflow.getSchedule.useQuery(
    { workflowId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    scheduledTask: data,
    isLoading,
    error,
  }
}

export function useFetchWorkflowRuns(workflowId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workflow.getRuns.useQuery(
    { workflowId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    runs: data,
    isLoading,
    error,
  }
}

export function usePublicFetchWorkflowRuns(workflowId: number) {
  const { data, isLoading, error } = api.publicAction.getWorkflowRuns.useQuery({ workflowId })
  return {
    runs: data,
    isLoading,
    error,
  }
}

export function useFetchWorkflowRunsForApp(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workflow.getRunsForApp.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    runs: data,
    isLoading,
    error,
  }
}

export function useFetchWorkflowStudioState(workflowId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workflow.getStudioUserState.useQuery(
    { id: workflowId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    state: data,
    isLoading,
    error,
  }
}

export function useFetchRunMetadataPoll(runGuid: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workflow.getRunMetadata.useQuery(
    { runGuid },
    {
      enabled: user !== undefined,
      refetchInterval: 1000,
    }
  )
  return {
    run: data,
    isLoading,
    error,
  }
}
