import { api } from '@/utils'
import { useUser } from './user'

export function useFetchFinetunes(appId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getAll.useQuery(
    { appId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    finetunes: data,
    isLoading,
    error,
  }
}

export function useFetchFinetune(finetuneId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.get.useQuery(
    { id: finetuneId },
    {
      enabled: user !== undefined,
      refetchInterval: 10000,
    }
  )
  return {
    finetune: data,
    isLoading,
    error,
  }
}

export function useFetchFinetuneBySlug(finetuneSlug: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getBySlug.useQuery(
    { slug: finetuneSlug },
    {
      enabled: user !== undefined,
      refetchInterval: 10000,
    }
  )
  return {
    finetune: data,
    isLoading,
    error,
  }
}

export function useFetchFinetunesForDataset(datasetGuid: string) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getAllForDataset.useQuery(
    { datasetGuid },
    {
      enabled: user !== undefined,
    }
  )
  return {
    finetunes: data,
    isLoading,
    error,
  }
}

export function useFetchFinetuneDataCount(finetuneId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getDataPointsCount.useQuery(
    { id: finetuneId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    count: data,
    isLoading,
    error,
  }
}

export function useFetchFinetuneStatus(finetuneId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getStatus.useQuery(
    { id: finetuneId },
    {
      enabled: user !== undefined,
      refetchInterval: 30000,
    }
  )
  return {
    status: data,
    isLoading,
    error,
  }
}

export function useFetchEvalRunsForFinetune(finetuneId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.finetune.getEvalRuns.useQuery(
    { id: finetuneId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    evalRuns: data,
    isLoading,
    error,
  }
}
