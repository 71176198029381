import { cva } from 'class-variance-authority'

export const tagButtonVariants = cva('rounded-sm outline-none hover:ring-2 focus-visible:ring-2', {
  variants: {
    variant: {
      default: 'hover:ring-grey-800/[.32] focus-visible:ring-grey-800/[.32]',
      primary: 'hover:ring-primary-light focus-visible:ring-primary-light',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export const tagIconVariants = cva('', {
  variants: {
    variant: {
      default: 'text-grey-800/[.32]',
      primary: 'text-primary-light',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})
