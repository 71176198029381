import { PlusCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import ChevronDownIcon from '@/client/assets/icons/chevron-down.svg'
import { Command, FieldMessage, Icon, Popover, Separator, Skeleton } from '@/client/components'
import { cn } from '@/client/utils'
import type {
  ComboboxItemProps,
  ComboboxSingleOption,
  ComboboxSingleProps,
  CommandGroupItemProps,
} from './types'

const ComboboxItem = ({ option }: ComboboxItemProps) => {
  const parentRef = React.useRef<HTMLDivElement>(null)

  const children = () => (
    <React.Fragment>
      {option.icon ? (
        <Icon
          className={cn(
            option.disabled ? 'text-grey-400 dark:text-grey-600' : 'text-grey-900 dark:text-white'
          )}
          size="lg"
          component={option.icon}
        />
      ) : null}
      <span>{option.label}</span>
    </React.Fragment>
  )

  return (
    <div
      ref={parentRef}
      className={cn(
        option.disabled
          ? 'cursor-not-allowed text-grey-400 dark:text-grey-600'
          : 'text-grey-900 dark:text-white',
        'overflow-x-auto no-scrollbar flex items-center gap-2'
      )}
      onMouseEnter={() => {
        if (!parentRef.current) return
        /* Don't marquee if there's no overflow. Avoid unnecessary scroll glitch. */
        if (parentRef.current?.scrollWidth < parentRef.current?.clientWidth) return
        parentRef.current.scrollTo({ left: parentRef.current.scrollWidth, behavior: 'smooth' })
      }}
      onMouseLeave={() => {
        if (!parentRef.current) return
        /* Don't marquee if there's no overflow. Avoid unnecessary scroll glitch. */
        if (parentRef.current?.scrollWidth < parentRef.current?.clientWidth) return
        parentRef.current.scrollTo({ left: 0, behavior: 'smooth' })
      }}
    >
      {children()}
    </div>
  )
}

const CommandGroupItem = ({
  options,
  value,
  heading,
  setOpen,
  onChange,
  onMouseEnter,
  onMouseLeave,
}: CommandGroupItemProps) => {
  return (
    <Command.Group heading={heading} onMouseLeave={onMouseLeave}>
      {options.map((option) => (
        <Command.Item
          key={option.value}
          className={cn('flex gap-2 text-sm', option.disabled ? 'cursor-not-allowed' : '')}
          value={option.value}
          onMouseEnter={(_) => {
            onMouseEnter?.(option.value)
          }}
          onSelect={(_) => {
            // Note: cmdk currently lowercases values in forms for some reason, so don't use value from callback parameter // https://github.com/pacocoursey/cmdk/issues/150
            if (onChange) {
              if (option.disabled) return
              onChange(option.value === value ? '' : option.value)
            }

            setOpen(false)
          }}
        >
          <ComboboxItem option={option} />
        </Command.Item>
      ))}
    </Command.Group>
  )
}

const ComboboxSingle = ({
  className,
  classNameContent,
  matchTargetWidth,
  label,
  options,
  groups,
  value = '',
  hideLabel,
  TriggerComponent,
  placeholder,
  placeholderSearch,
  disabled,
  isLoading,
  emptyText,
  message,
  hasError,
  onChange,
  onMouseEnter,
  onMouseLeave,
  modal = true,
  onSelectCreate,
  title,
}: ComboboxSingleProps) => {
  const [open, setOpen] = React.useState(false)

  // Sort options alphabetically
  const optionsSortedAlphabetically = React.useMemo(
    () => options?.sort((a, b) => a.label.localeCompare(b.label)),
    [options]
  )

  let allOptions: ComboboxSingleOption[] = []
  if (optionsSortedAlphabetically) {
    allOptions = optionsSortedAlphabetically
  } else if (groups) {
    allOptions = groups.reduce(
      (prev: ComboboxSingleOption[], group) => [...prev, ...group.options],
      []
    )
  }

  const selectedOption = value && allOptions.find((option) => option.value === value)

  return (
    <div className={cn('relative', className)}>
      <label
        className={cn(
          'body2 mb-1.5 block font-medium text-grey-800',
          { 'text-grey-400 dark:text-grey-600': disabled },
          {
            'sr-only': hideLabel,
          }
        )}
      >
        {label}
      </label>

      {/*ScrollArea is not working in Popover - modal fixes it https://github.com/shadcn-ui/ui/issues/542#issuecomment-1587142689 */}
      <Popover.Root open={open} onOpenChange={setOpen} modal={modal}>
        <Popover.Trigger asChild disabled={disabled}>
          {TriggerComponent ? (
            TriggerComponent
          ) : (
            <button
              className={cn(
                'flex font-inter w-full whitespace-nowrap items-center justify-between rounded-md border bg-white px-3 py-2 text-sm outline-none',
                open
                  ? 'border-grey-400 dark:border-white'
                  : 'border-grey-300 hover:border-grey-400 focus-within:border-grey-400 dark:border-grey-600 dark:focus-within:border-white',
                { 'border-error focus-within:border-error': hasError },
                { 'border-grey-100 bg-grey-100 dark:border-grey-700 dark:bg-grey-700': disabled }
              )}
              // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
              role="combobox"
              aria-expanded={open}
            >
              {selectedOption ? (
                <ComboboxItem option={selectedOption} />
              ) : (
                <span className="text-grey-500 dark:text-grey-600">
                  {placeholder || 'Select...'}
                </span>
              )}

              <Icon
                className={cn(
                  'shrink-0 transition-transform duration-200',
                  { 'rotate-180': open },
                  disabled ? 'text-grey-300 dark:text-grey-600' : 'text-grey-500'
                )}
                size="md"
                component={ChevronDownIcon}
              />
            </button>
          )}
        </Popover.Trigger>

        <Popover.Content
          className={cn('p-0', classNameContent)}
          matchTargetWidth={matchTargetWidth}
          collisionPadding={20}
        >
          <Command.Root
            filter={(value, search) => {
              if (value.includes(search.toLowerCase())) return 1 // optimization: if value and label are equal, then we don't have to search for label

              const label = allOptions.find((opt) => opt.value.toLowerCase() === value)?.label
              if (!label) return 0

              return label.toLowerCase().includes(search.toLowerCase()) ? 1 : 0
            }}
          >
            <div className="flex flex-col">
              <Command.Input placeholder={placeholderSearch || 'Search...'} />
              <Separator orientation={'horizontal'} />

              {isLoading ? (
                <div className="my-2 flex">
                  <div className="flex-1 space-y-4">
                    <Skeleton />
                    <Skeleton />
                  </div>
                </div>
              ) : (
                <>
                  <Command.Empty>{emptyText || 'Not found.'}</Command.Empty>

                  {onSelectCreate && (
                    <Command.Item
                      key="create"
                      onSelect={() => {
                        onSelectCreate()
                      }}
                    >
                      <PlusCircleIcon className={'mr-2 w-4 h-4'} />

                      <span className={'font-normal'}>New {title}</span>
                    </Command.Item>
                  )}

                  <div className="max-h-[370px] overflow-y-scroll">
                    {optionsSortedAlphabetically ? (
                      <CommandGroupItem
                        options={optionsSortedAlphabetically}
                        value={value}
                        setOpen={setOpen}
                        onChange={onChange}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      />
                    ) : null}

                    {groups
                      ? groups.map(({ heading, options }, index) => {
                          return (
                            <CommandGroupItem
                              key={index}
                              options={options}
                              value={value}
                              heading={heading}
                              setOpen={setOpen}
                              onChange={onChange}
                            />
                          )
                        })
                      : null}
                  </div>
                </>
              )}
            </div>
          </Command.Root>
        </Popover.Content>
      </Popover.Root>

      {message ? <FieldMessage message={message} disabled={disabled} hasError={hasError} /> : null}
    </div>
  )
}

ComboboxSingle.displayName = 'ComboboxSingle'

export { ComboboxSingle }
