import { extractSchema } from 'barhandles-ts'

export const getTemplateVars = (promptTemplate?: string) => {
  if (!promptTemplate) return []

  let templateVars: string[] = []
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const schema: Record<string, any> = extractSchema(promptTemplate)

    const vars: string[] = Object.keys(schema)
      .filter((key) => key !== '_type')
      .filter((key) => key !== 'locals')
    templateVars = [...new Set(vars)]
  } catch (error) {}

  return templateVars
}
