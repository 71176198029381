/* eslint-disable @typescript-eslint/ban-ts-comment */
import { z } from 'zod'

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
const server = z.object({
  DATABASE_URL: z.string().url(),
  DOCUMENTS_DATABASE_URL: z.string().url(),
  NODE_ENV: z.enum(['development', 'test', 'production']),
  NEXTAUTH_SECRET: z.string().min(1),
  NEXTAUTH_URL: z.preprocess(
    // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
    // Since NextAuth.js automatically uses the VERCEL_URL if present.
    (str) => process.env.VERCEL_URL ?? str,
    // VERCEL_URL doesn't include `https` so it cant be validated as a URL
    process.env.VERCEL ? z.string().min(1) : z.string().url()
  ),
  // Add `.min(1) on ID and SECRET if you want to make sure they're not empty
  GOOGLE_CLIENT_ID: z.string().min(1),
  GOOGLE_CLIENT_SECRET: z.string().min(1),
  GITHUB_CLIENT_ID: z.string().min(1),
  GITHUB_CLIENT_SECRET: z.string().min(1),
  EMAIL_SERVER_USER: z.string().min(1),
  EMAIL_SERVER_PASSWORD: z.string().min(1),
  EMAIL_SERVER_HOST: z.string().min(1),
  EMAIL_SERVER_PORT: z.string().min(1),
  EMAIL_FROM: z.string().min(1),
  AWS_ACCESS_KEY: z.string().min(1),
  AWS_SECRET_KEY: z.string().min(1),
  AWS_S3_BUCKET_NAME: z.string().min(1),
  AWS_S3_REGION: z.string().min(1),
  ENGINE_URL: z.string().min(1),
  SENTRY_DSN: z.string().min(1),
  SEGMENT_WRITE_KEY: z.string().min(1),
  NANGO_SECRET_KEY: z.string().min(1),
  NOVU_API_KEY: z.string().min(1),
  // NOVU_BACKEND_URL: z.string().min(1),
  KLU_DATA_SOURCE_HEADER: z.string().min(1),
  REDIS_URL: z.string().min(1),
  TIER_BASE_URL: z.string().min(1),
  TIER_API_KEY: z.string().min(1),
  TIER_AUTH_USER: z.string().min(1),
  TIER_AUTH_PASSWORD: z.string().min(1),
  ENGINE_CACHE_PREFIX: z.string().min(1),
  PINO_LOG_LEVEL: z
    .enum(['fatal', 'error', 'warn', 'info', 'debug', 'trace', 'silent'])
    .default('info'),
  LIVEBLOCKS_API_SECRET: z
    .string()
    .min(1)
    .default('sk_dev_p5LJeeznBzTVcw7OXZPsMbuO5VsaJNZfNEailaLcpqTG_FrEi2Tnnn_ukhrR1YAS'),
  LIVEBLOCKS_WEBHOOK_SECRET: z.string().min(1).default('whsec_oqntHo43iHu57s0hwNRbFjxfVI81xorv'),
  GRAPHILE_DATABASE_URL: z.string().url(),
  GATEWAY_URL: z.string().url(),
  KLU_API_KEY: z.string().min(1),
  KLU_USER_ID: z.string().min(1),
  SENTRY_BACKEND_DSN: z.string().min(1),
  KLU_AZURE_GATEWAY_URL: z.string().min(1),
  KLU_AZURE_GATEWAY_KEY: z.string().min(1),
  GRAPHILE_CONCURRENT_JOBS: z.string().min(1),
  RISKY_COUNTRY_CODES: z.string().min(1),

  KLU_EMBEDDING_API_BASE_0: z.string().min(1),
  KLU_EMBEDDING_API_BASE_1: z.string().min(1),
  KLU_EMBEDDING_API_BASE_2: z.string().min(1),
  KLU_EMBEDDING_API_BASE_3: z.string().min(1),
  KLU_EMBEDDING_API_BASE_4: z.string().min(1),
  KLU_EMBEDDING_API_BASE_5: z.string().min(1),
  KLU_EMBEDDING_API_BASE_6: z.string().min(1),
  KLU_EMBEDDING_API_BASE_7: z.string().min(1),
  KLU_EMBEDDING_API_BASE_8: z.string().min(1),
  KLU_EMBEDDING_API_BASE_9: z.string().min(1),

  KLU_EMBEDDING_API_KEY_0: z.string().min(1),
  KLU_EMBEDDING_API_KEY_1: z.string().min(1),
  KLU_EMBEDDING_API_KEY_2: z.string().min(1),
  KLU_EMBEDDING_API_KEY_3: z.string().min(1),
  KLU_EMBEDDING_API_KEY_4: z.string().min(1),
  KLU_EMBEDDING_API_KEY_5: z.string().min(1),
  KLU_EMBEDDING_API_KEY_6: z.string().min(1),
  KLU_EMBEDDING_API_KEY_7: z.string().min(1),
  KLU_EMBEDDING_API_KEY_8: z.string().min(1),
  KLU_EMBEDDING_API_KEY_9: z.string().min(1),

  OPENAI_API_KEY: z.string().min(1),

})

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  // NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
  NEXT_PUBLIC_SENTRY_DSN: z.string().min(1),
  NEXT_PUBLIC_NODE_ENV: z.string().min(1),
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: z.string().min(1),
  NEXT_PUBLIC_NANGO_HOST: z.string().min(1),
  NEXT_PUBLIC_ENGINE_URL: z.string().min(1),
  NEXT_PUBLIC_NANGO_PUBLIC_KEY: z.string().min(1),
  NEXT_PUBLIC_NOVU_APP_ID: z.string().min(1),
  NEXT_PUBLIC_API_URL: z.string().default('https://data-api.klu.ai'),
})

/**
 * You can't destruct `process.env` as a regular object in the Next.js
 * edge runtimes (e.g. middlewares) or client-side so we need to destruct manually.
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  DATABASE_URL: process.env.DATABASE_URL,
  DOCUMENTS_DATABASE_URL: process.env.DOCUMENTS_DATABASE_URL,
  NODE_ENV: process.env.NODE_ENV,
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
  NEXTAUTH_URL: process.env.NEXTAUTH_URL,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
  GITHUB_CLIENT_ID: process.env.GITHUB_CLIENT_ID,
  GITHUB_CLIENT_SECRET: process.env.GITHUB_CLIENT_SECRET,
  EMAIL_SERVER_USER: process.env.EMAIL_SERVER_USER,
  EMAIL_SERVER_PASSWORD: process.env.EMAIL_SERVER_PASSWORD,
  EMAIL_SERVER_HOST: process.env.EMAIL_SERVER_HOST,
  EMAIL_SERVER_PORT: process.env.EMAIL_SERVER_PORT,
  EMAIL_FROM: process.env.EMAIL_FROM,
  AWS_ACCESS_KEY: process.env.AWS_ACCESS_KEY,
  AWS_SECRET_KEY: process.env.AWS_SECRET_KEY,
  AWS_S3_BUCKET_NAME: process.env.AWS_S3_BUCKET_NAME,
  AWS_S3_REGION: process.env.AWS_S3_REGION,
  ENGINE_URL: process.env.ENGINE_URL,
  SENTRY_DSN: process.env.SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
  SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  NEXT_PUBLIC_NANGO_HOST: process.env.NEXT_PUBLIC_NANGO_HOST,
  NANGO_SECRET_KEY: process.env.NANGO_SECRET_KEY,
  KLU_DATA_SOURCE_HEADER: process.env.KLU_DATA_SOURCE_HEADER,
  NEXT_PUBLIC_ENGINE_URL: process.env.NEXT_PUBLIC_ENGINE_URL,
  NEXT_PUBLIC_NANGO_PUBLIC_KEY: process.env.NEXT_PUBLIC_NANGO_PUBLIC_KEY,
  REDIS_URL: process.env.REDIS_URL,
  TIER_BASE_URL: process.env.TIER_BASE_URL,
  TIER_API_KEY: process.env.TIER_API_KEY,
  TIER_AUTH_USER: process.env.TIER_AUTH_USER,
  TIER_AUTH_PASSWORD: process.env.TIER_AUTH_PASSWORD,
  ENGINE_CACHE_PREFIX: process.env.ENGINE_CACHE_PREFIX,
  // NEXT_PUBLIC_CLIENTVAR: process.env.NEXT_PUBLIC_CLIENTVAR,
  NOVU_API_KEY: process.env.NOVU_API_KEY,
  // NOVU_BACKEND_URL: process.env.NOVU_BACKEND_URL,
  NEXT_PUBLIC_NOVU_APP_ID: process.env.NEXT_PUBLIC_NOVU_APP_ID,
  PINO_LOG_LEVEL: process.env.PINO_LOG_LEVEL,
  LIVEBLOCKS_API_SECRET: process.env.LIVEBLOCKS_API_SECRET,
  LIVEBLOCKS_WEBHOOK_SECRET: process.env.LIVEBLOCKS_WEBHOOK_SECRET,
  GRAPHILE_DATABASE_URL: process.env.GRAPHILE_DATABASE_URL,
  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
  GATEWAY_URL: process.env.GATEWAY_URL,
  KLU_API_KEY: process.env.KLU_API_KEY,
  KLU_USER_ID: process.env.KLU_USER_ID,
  SENTRY_BACKEND_DSN: process.env.SENTRY_BACKEND_DSN,
  KLU_AZURE_GATEWAY_URL: process.env.KLU_AZURE_GATEWAY_URL,
  KLU_AZURE_GATEWAY_KEY: process.env.KLU_AZURE_GATEWAY_KEY,
  GRAPHILE_CONCURRENT_JOBS: process.env.GRAPHILE_CONCURRENT_JOBS,
  RISKY_COUNTRY_CODES: process.env.RISKY_COUNTRY_CODES,

  KLU_EMBEDDING_API_BASE_0: process.env.KLU_EMBEDDING_API_BASE_0,

  KLU_EMBEDDING_API_BASE_1: process.env.KLU_EMBEDDING_API_BASE_1,
  KLU_EMBEDDING_API_BASE_2: process.env.KLU_EMBEDDING_API_BASE_2,
  KLU_EMBEDDING_API_BASE_3: process.env.KLU_EMBEDDING_API_BASE_3,
  KLU_EMBEDDING_API_BASE_4: process.env.KLU_EMBEDDING_API_BASE_4,
  KLU_EMBEDDING_API_BASE_5: process.env.KLU_EMBEDDING_API_BASE_5,
  KLU_EMBEDDING_API_BASE_6: process.env.KLU_EMBEDDING_API_BASE_6,
  KLU_EMBEDDING_API_BASE_7: process.env.KLU_EMBEDDING_API_BASE_7,
  KLU_EMBEDDING_API_BASE_8: process.env.KLU_EMBEDDING_API_BASE_8,
  KLU_EMBEDDING_API_BASE_9: process.env.KLU_EMBEDDING_API_BASE_9,
  KLU_EMBEDDING_API_KEY_0: process.env.KLU_EMBEDDING_API_KEY_0,
  KLU_EMBEDDING_API_KEY_1: process.env.KLU_EMBEDDING_API_KEY_1,
  KLU_EMBEDDING_API_KEY_2: process.env.KLU_EMBEDDING_API_KEY_2,
  KLU_EMBEDDING_API_KEY_3: process.env.KLU_EMBEDDING_API_KEY_3,
  KLU_EMBEDDING_API_KEY_4: process.env.KLU_EMBEDDING_API_KEY_4,
  KLU_EMBEDDING_API_KEY_5: process.env.KLU_EMBEDDING_API_KEY_5,
  KLU_EMBEDDING_API_KEY_6: process.env.KLU_EMBEDDING_API_KEY_6,
  KLU_EMBEDDING_API_KEY_7: process.env.KLU_EMBEDDING_API_KEY_7,
  KLU_EMBEDDING_API_KEY_8: process.env.KLU_EMBEDDING_API_KEY_8,
  KLU_EMBEDDING_API_KEY_9: process.env.KLU_EMBEDDING_API_KEY_9,

  OPENAI_API_KEY: process.env.OPENAI_API_KEY,

}

// Don't touch the part below
// --------------------------

const merged = server.merge(client)
/** @type z.infer<merged>
 *  @ts-ignore - can't type this properly in jsdoc */
let env = process.env

if (!!process.env.SKIP_ENV_VALIDATION == false) {
  const isServer = typeof window === 'undefined'

  const parsed = isServer
    ? merged.safeParse(processEnv) // on server we can validate all env vars
    : client.safeParse(processEnv) // on client we can only validate the ones that are exposed

  if (parsed.success === false) {
    console.error('❌ Invalid environment variables:', parsed.error.flatten().fieldErrors)
    throw new Error('Invalid environment variables')
  }

  /** @type z.infer<merged>
   *  @ts-ignore - can't type this properly in jsdoc */
  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== 'string') return undefined
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith('NEXT_PUBLIC_'))
        throw new Error(
          process.env.NODE_ENV === 'production'
            ? '❌ Attempted to access a server-side environment variable on the client'
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`
        )
      /*  @ts-ignore - can't type this properly in jsdoc */
      return target[prop]
    },
  })
}

export { env }
