import { api } from '@/utils'

export function useFetchLoaderTypes(workspaceGuid: string) {
  const { data, isLoading, error } = api.loaderType.get.useQuery({ workspaceGuid })
  return {
    loaderTypes: data,
    isLoading,
    error,
  }
}
