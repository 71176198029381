/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import handlebars from 'handlebars'
import { asyncHelpers } from 'handlebars-async-helpers-ts'
import { array, comparison, math, number, string } from 'handlebars-helpers-lite'
import TurndownService from 'turndown'

const hbrs: typeof handlebars = asyncHelpers(handlebars)

hbrs.registerHelper({
  ...comparison,
  ...math,
  ...number,
  ...string,
  ...array,
})

//override the split helper from handlebars-helpers-lite
hbrs.registerHelper('split', function (str, ch) {
  const isString = typeof str === 'string' || str instanceof hbrs.SafeString
  if (!isString) return ''
  if (!(typeof ch === 'string')) ch = ','
  return str.toString().split(ch)
})

hbrs.registerHelper('repeat', async function (this: any, times: number, options) {
  let out = ''

  let data: Record<string, any> = {}
  if (options.data) {
    data = handlebars.Utils.createFrame(options.data)
  }

  for (let i = 0; i < (times || 2); i++) {
    data.index = i
    out += (await options.fn(this, { data })) as string
  }
  return out
})

hbrs.registerHelper('assignLocal', async function (this: any, varName, options) {
  const { hash } = options
  const { append } = hash
  if (!this.locals) {
    this.locals = {}
  }
  const val = (await options.fn(this)) as string
  if (append) {
    this.locals[varName] = ((this.locals[varName] as string) || '') + val.toString()
  } else {
    this.locals[varName] = val
  }

  return ''
})

hbrs.registerHelper('fetch', async function (this: any, options: any): Promise<any> {
  console.log('fetch', options)
  const { fn } = options

  if (options.data?.runDynamicBlocks) {
    const content = (await fn(this)) as string
    const fetchBlockResult = await runFetchBlock(content, options?.hash)
    return await new Promise((resolve) => {
      resolve(new handlebars.SafeString(fetchBlockResult))
    })
  } else {
    const content = (await fn(this)) as string
    return await new Promise((resolve) => {
      resolve(new handlebars.SafeString(`{#fetch}${content}{/fetch}`).toString())
    })
  }
})

const runFetchBlock = async (content: string, options?: { format: string }) => {
  let json: Record<string, any> | null = null
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    json = JSON.parse(content)
  } catch (e) {}

  let output = ''

  try {
    if (json && json.url) {
      const { url, ...params } = json
      const response = await fetch(url as string, params)
      output = await response.text()
    } else {
      const url = content
      const response = await fetch(url)
      output = await response.text()
    }
  } catch {
    console.log('Error fetching', content)
    output = `Error fetching ${content}`
  }

  try {
    const jsonOutput = JSON.parse(output)
    return jsonOutput
  } catch {
    if (options?.format == 'html') return output
    return convertToMarkdown(output)
  }
}

const convertToMarkdown = (html: string) => {
  const turndownService = new TurndownService()
  const markdown = turndownService
    .addRule('title', {
      filter: ['title'],
      replacement: function (content: string) {
        return content ? `Page Title: ${content}` : ''
      },
    })
    .remove(['style', 'script'])
    .turndown(html)
  return markdown
}

hbrs.registerHelper(
  'generate',
  async function (this: any, options: any, _context: any): Promise<any> {
    const { fn, hash } = options
    const { variable, hide } = hash
    let output: string | null = null

    if (options.data?.runDynamicBlocks) {
      const content = (await fn(this)).toString()
      try {
        const llmBlockResult = await runLlmBlock(content, options)
        output = await new Promise((resolve) => {
          resolve(new handlebars.SafeString(llmBlockResult).toString())
        })
      } catch (e) {
        output = await new Promise((resolve) => {
          resolve(
            new handlebars.SafeString(
              `Error running LLM block: ${(content as string).trim()}: ${e as string}`
            ).toString()
          )
        })
      }
    } else {
      const content = (await fn(this)) as string
      output = variable ? `$\{${variable as string}\}` : `{{#generate}}${content}{{/generate}}`
      output = await new Promise((resolve) => {
        resolve(new handlebars.SafeString(output as string).toString())
      })
    }

    if (variable && variable != '') {
      if (!this.locals) {
        this.locals = {}
      }
      this.locals[variable] = output
    }

    if (!hide || !Boolean(JSON.parse(hide))) {
      return output
    } else {
      return ''
    }
  }
)

const runLlmBlock = async (
  input: string,
  options: {
    hash: { actionGuid: string; temperature: number; max_tokens: number }
    data: { userId: string; apikey: string; modelId: number }
  }
) => {
  console.log('runLlmBlock', input, options)
  const actionGuid = options.hash.actionGuid
  const userId = options.data.userId
  const apikey = options.data.apikey
  const modelId = options.data.modelId

  let res = ''
  if (actionGuid) {
    res = await runAction(input, actionGuid, userId, apikey)
  } else {
    if (typeof window === 'undefined') {
      const { promptService } = await import('../server/service/prompt')
      res = await promptService.runPrompt(input, modelId, {
        temperature: options.hash?.temperature,
        max_tokens: options.hash?.max_tokens,
      })
    }
  }

  return typeof res === 'string' ? res : JSON.stringify(res)
}

const runAction = async (input: string, actionGuid: string, userId: string, apikey: string) => {
  let res = input
  if (typeof window === 'undefined') {
    const { promptService } = await import('../server/service/prompt')
    const { response, error } = await promptService.runAction(
      {
        guid: actionGuid,
        input,
      },
      {
        userId,
        apikey,
        source: 'runLlmBlock',
      }
    )
    if (error) {
      console.log('runLlmBlock error', error)
      res = error.message
    } else if (response) {
      res =
        response.choices?.[0]?.message.content ||
        JSON.stringify(response.choices?.[0]?.message.tool_calls)
    }
  }
  console.log('runLlmBlock res', res)
  return res
}

export default hbrs
