import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/solid'
import * as React from 'react'
import { Button, Command, Popover, ScrollArea } from '@/client/components'
import { cn } from '@/client/utils'

export type ComboboxOptions = {
  value: string
  label: string
}

type Mode = 'single' | 'multiple'

interface ComboboxProps {
  mode?: Mode
  options: ComboboxOptions[]
  selected: string | string[] // Updated to handle multiple selections
  className?: string
  placeholder?: string
  onChange?: (event: string | string[]) => void // Updated to handle multiple selections
  onCreate?: (value: string) => void
}

export function ComboboxOrCreate({
  options,
  selected,
  className,
  placeholder,
  mode = 'single',
  onChange,
  onCreate,
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false)
  const [query, setQuery] = React.useState<string>('')

  return (
    <div className={cn('block', className)}>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <Button
            key={'combobox-trigger'}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            {selected && selected.length > 0 ? (
              <div className="relative mr-auto flex flex-grow flex-wrap items-center overflow-hidden">
                <span>
                  {mode === 'multiple' && Array.isArray(selected)
                    ? selected
                        .map(
                          (selectedValue: string) =>
                            options.find((item) => item.value === selectedValue)?.label
                        )
                        .join(', ')
                    : mode === 'single' && options.find((item) => item.value === selected)?.label}
                </span>
              </div>
            ) : (
              placeholder ?? 'Select Item...'
            )}
            <ChevronUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </Popover.Trigger>
        <Popover.Content className="w-72 max-w-sm p-0">
          <Command.Root
            filter={(value, search) => {
              if (value.includes(search)) return 1
              return 0
            }}
            // shouldFilter={true}
          >
            <Command.Input
              placeholder={placeholder ?? 'Search...'}
              value={query}
              onValueChange={(value: string) => setQuery(value)}
            />
            <Command.Empty
              onClick={() => {
                if (onCreate) {
                  onCreate(query)
                  setQuery('')
                }
              }}
              className="flex cursor-pointer items-center justify-center gap-1 italic"
            >
              <div className="py-2">
                <p>Create new option: </p>
                <p className="block truncate font-semibold">{query}</p>
              </div>
            </Command.Empty>
            <ScrollArea>
              <div className="max-h-80">
                <Command.Group>
                  {options.map((option) => (
                    <Command.Item
                      key={option.label}
                      value={option.label}
                      onSelect={() => {
                        if (onChange) {
                          if (mode === 'multiple' && Array.isArray(selected)) {
                            onChange(
                              selected.includes(option.value)
                                ? selected.filter((item) => item !== option.value)
                                : [...selected, option.value]
                            )
                          } else {
                            onChange(option.value)
                          }
                        }
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          'mr-2 h-4 w-4',
                          selected.includes(option.value) ? 'opacity-100' : 'opacity-0'
                        )}
                      />
                      {option.label}
                    </Command.Item>
                  ))}
                </Command.Group>
              </div>
            </ScrollArea>
          </Command.Root>
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}
