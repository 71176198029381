import { encoding_for_model, type TiktokenModel } from '@dqbd/tiktoken'
import { useEffect, useState } from 'react'
import { getTemplateVars } from '@/client/utils/templateVars'

function getVariablesFromPrompt(prompt: string) {
  return getTemplateVars(prompt)
}

export function getMetadataForPromptVariables(prompt?: string) {
  if (!prompt) {
    return {}
  }
  const variables = getVariablesFromPrompt(prompt)
  let metadata: Record<string, string[]> = {}
  if (variables.length) {
    metadata = {
      variables: variables,
    }
  }
  return metadata
}

export const useTokenCount = function (prompt: string, modelName: string) {
  const [tokenCount, setTokenCount] = useState(0)
  useEffect(() => {
    if (modelName) {
      let encoder = null
      try {
        encoder = encoding_for_model(modelName as TiktokenModel)
      } catch (error) {
        encoder = encoding_for_model('text-davinci-003')
      }
      try {
        if (encoder && prompt) {
          const encoded = encoder.encode(prompt)
          encoder.free()
          setTokenCount(encoded.length)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [modelName, prompt])

  return { tokenCount }
}
