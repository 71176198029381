import * as SelectPrimitive from '@radix-ui/react-select'
import React from 'react'
import CheckIcon from '@/client/assets/icons/check.svg'
import { Icon } from '@/client/components/Icon'
import { cn } from '@/client/utils'
import type { SelectItemProps } from './types'

const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, SelectItemProps>(
  ({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        'bg-white relative text-xs font-regular flex w-full cursor-pointer select-none items-center rounded-md py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-grey-100 focus:text-grey-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-grey-800 dark:focus:text-grey-50',
        className
      )}
      {...props}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <SelectPrimitive.ItemIndicator>
          <Icon size="sm" component={CheckIcon} />
        </SelectPrimitive.ItemIndicator>
      </span>

      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  )
)

SelectItem.displayName = SelectPrimitive.Item.displayName

export { SelectItem }
