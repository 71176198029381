import { z } from 'zod'

export const toolCallSchema = z.object({
  id: z.string(),
  type: z.literal('function'),
  function: z.object({
    arguments: z.string(),
    name: z.string(),
  }),
})

export const messageSchema = z.object({
  role: z.enum(['user', 'assistant', 'system', 'tool']),
  content: z.union([z.string(), z.null()]).default(''),
  tool_calls: z.array(toolCallSchema).optional(),
  tool_call_id: z.string().optional(),
  dataGuid: z.string().optional(),
  files: z.array(z.union([z.string(), z.string().url()])).optional(),
  duration: z.number().optional(),
  token: z.number().optional(),
})
