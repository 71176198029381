import { FieldMessage, Select } from '@/client/components'
import { cn } from '@/client/utils'
import type { SelectFieldProps } from './types'

const SelectField = ({
  className,
  id,
  label,
  options,
  value,
  hideLabel,
  placeholder,
  disabled,
  message,
  hasError,
  onChange,
}: SelectFieldProps) => {
  return (
    <div className={cn('relative', className)}>
      {label && (
        <label
          className={cn(
            'body2 mb-1.5 block font-medium',
            disabled ? 'text-grey-300 dark:text-grey-600' : 'text-grey-800',
            { 'sr-only': hideLabel }
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <Select.Root value={value ? value : undefined} disabled={disabled} onValueChange={onChange}>
        <Select.Trigger id={id} hasError={hasError}>
          <Select.Value placeholder={placeholder} />
        </Select.Trigger>

        <Select.Content>
          {options.map((option, index) => {
            return (
              <Select.Item key={index} value={option.value}>
                <div className="flex items-center space-x-2">
                  {option.image ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                      src={option.image}
                      alt={option.label}
                      className="w-6 h-6 mr-2 rounded-full"
                    />
                  ) : null}
                  <div>{option.label}</div>
                </div>
              </Select.Item>
            )
          })}
        </Select.Content>
      </Select.Root>

      {message ? <FieldMessage message={message} disabled={disabled} hasError={hasError} /> : null}
    </div>
  )
}

SelectField.displayName = 'SelectField'

export { SelectField }
