import React from 'react'
import { cn } from '@/client/utils'
import type { DialogFooterProps } from './types'

const DialogFooter = ({ className, ...props }: DialogFooterProps) => (
  <div
    className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)}
    {...props}
  />
)

DialogFooter.displayName = 'DialogFooter'

export { DialogFooter }
