import React from 'react'
import { cn } from '@/client/utils'
import type { DialogHeaderProps } from './types'

const DialogHeader = ({ className, ...props }: DialogHeaderProps) => (
  <div className={cn('flex flex-col space-y-1.5 text-center sm:text-left', className)} {...props} />
)

DialogHeader.displayName = 'DialogHeader'

export { DialogHeader }
