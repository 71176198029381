import * as TabsPrimitive from '@radix-ui/react-tabs'
import { TabsContent } from './Content'
import { TabsList } from './List'
import { TabsTrigger } from './Trigger'

const Tabs = {
  Root: TabsPrimitive.Root,
  List: TabsList,
  Trigger: TabsTrigger,
  Content: TabsContent,
}

export { Tabs }
