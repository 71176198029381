import XCloseIcon from '@/client/assets/icons/x-close.svg'
import { Badge, Icon } from '@/client/components'
import { cn } from '@/client/utils'
import type { TagProps } from './types'
import { tagButtonVariants, tagIconVariants } from './variants'

const Tag = ({ children, onRemove, ...props }: TagProps) => {
  const { variant } = props

  return (
    <Badge {...props}>
      <div>{children}</div>

      {onRemove ? (
        <button
          className={cn(tagButtonVariants({ variant }))}
          aria-label="Remove tag"
          onClick={onRemove}
        >
          <Icon className={cn(tagIconVariants({ variant }))} size="xs" component={XCloseIcon} />
        </button>
      ) : null}
    </Badge>
  )
}

export { Tag }
