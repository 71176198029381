import * as PopoverPrimitive from '@radix-ui/react-popover'
import React from 'react'
import { cn } from '@/client/utils'
import type { PopoverTriggerProps } from './types'

const PopoverTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  PopoverTriggerProps
>(({ className, ...props }, ref) => (
  <PopoverPrimitive.Trigger
    ref={ref}
    className={cn(
      `
        text-grey-800
        dark:text-grey-50
      `,
      className
    )}
    {...props}
  />
))

PopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName

export { PopoverTrigger }
