import { api } from '@/utils'
import { useUser } from './user'

export function useFetchKeys(workspaceId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspaceKey.getAll.useQuery(
    { workspaceId },
    {
      enabled: user !== undefined,
    }
  )
  return {
    keys: data,
    isLoading,
    error,
  }
}

export function useFetchKeyLastUsed(apikeyId: number) {
  const { user } = useUser()
  const { data, isLoading, error } = api.workspaceKey.getLastUsed.useQuery(
    {
      apikeyId,
    },
    {
      enabled: user !== undefined,
    }
  )
  return {
    apikeyLastUsed: data,
    isLoading,
    error,
  }
}
