import React from 'react'
import { cn } from '@/client/utils'
import { ICON_SIZE_CONFIG } from './constants'
import type { IconProps } from './types'

const Icon = React.forwardRef<HTMLDivElement, IconProps>(
  ({ className, size, component: Component, ...props }, ref) => {
    const sizeValue = ICON_SIZE_CONFIG[size]

    return (
      <div
        className={cn(
          `flex h-${sizeValue} w-${sizeValue} items-center justify-center [&_svg]:h-full [&_svg]:w-full`,
          className
        )}
        ref={ref}
        {...props}
      >
        <Component aria-hidden={props['aria-hidden']} />
      </div>
    )
  }
)

Icon.displayName = 'Icon'

export { Icon }
